import * as yup from "yup";
export const BrandSchema = yup.object().shape({
    title: yup.string().required("Blog Title field can not be empty."),
    images: yup.mixed().test("file", "You need to provide a file", (value) => {
        if (value.length > 0) {
            return true;
        }
        return true;
    }),
})
