import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const handel_Images = async (images) => {
    try {
        if (!images || images.length === 0) {
            return false;
        }
        const formData = new FormData();
        const filename = uuidv4();
        formData.append('file', images[0]);
        formData.append('public_id', filename);
        formData.append('upload_preset', 'p36dm5k4');
        const response = await axios.post('https://api.cloudinary.com/v1_1/retwho/image/upload', formData);
        console.log(response)
        return response;
    } catch (error) {
        console.log("Error uploading image:", error);
    }
}
export default handel_Images;
