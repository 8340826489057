import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import GetSpinner from '../helpers/shared/GetSpinner'
import { useState } from 'react'
import BlogModal from '../components/elements/modal/brand/BlogModal'
import BlogModalEdit from '../components/elements/modal/brand/BlogModalEdit'
import { useGetBlogQuery } from '../app/services/blog'
import { useMemo } from 'react'
import SingleBlog from '../components/elements/blog/SingleBlog'
import { useEffect } from 'react'
import { useGetCategoriesQuery } from '../app/services/categories'
function Blog() {
    /* get Categories */
    const { data: categories } = useGetCategoriesQuery()
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [blogCreateModal, setBlogCreateModal] = useState({ type: false, data: null })
    const [blogEditModal, setBlogEditModal] = useState({ type: false, data: null })
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 20, });
    const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}`;
    const { data, isLoading } = useGetBlogQuery(pathname)

    const BlogData = useMemo(() => (data ? data?.blog : []), [
        data,
        search
    ]);
    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        }
        if (data) {
            setLoading(false)
        }

    }, [isLoading, data])



    return (
        <DashboardLayout>
            <section className="content-main">
                <div className=" content-header">
                    <div>
                        <h2 className="content-title card-title">Blog</h2>
                        <p>Add, edit or delete a Blog</p>
                    </div>
                    <div>
                        <a
                            onClick={() => setBlogCreateModal({ type: !blogCreateModal.type })}
                            className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Create Blog</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Images</th>
                                                <th>Title</th>
                                                <th>Category</th>
                                                <th>Discretion</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading && <GetSpinner />}
                                            {BlogData?.map(data => <SingleBlog data={data} key={data._id}
                                                setOpen={setBlogEditModal}
                                            />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*========== create itms  ==========   */}
            <BlogModal
                categories={categories}
                modal={blogCreateModal}
                setOpen={setBlogCreateModal}
            />
            {/*========== Edit itms  ==========   */}
            <BlogModalEdit
                categories={categories}
                modal={blogEditModal}
                setOpen={setBlogEditModal}
            />
        </DashboardLayout>
    )
}

export default Blog