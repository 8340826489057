import React from "react";
import { useUpdatebrandMutation } from "../../../app/services/brand";
import { BrandSchema } from "../../../helpers/validation/Brand";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import handel_Images from "../HandelImages";
import { useEffect } from "react";
import { toast } from "react-toastify";

function BrandEdit({ modal, setOpen }) {
    const { type, data } = modal;
    const [brandUpdate, { isLoading, isSuccess, isError }] = useUpdatebrandMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(BrandSchema) });
    const onSubmit = async (value) => {
        const images = value?.images;
        const response = await handel_Images(images)
        if (response) {
            const updateValue = { id: data?._id, title: value?.title, images: response?.data?.secure_url }
            await brandUpdate(updateValue)
        } else {
            const updateValue = { id: data?._id, title: value?.title, images: data?.images }
            await brandUpdate(updateValue)
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Brand Edit !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry brand not add!')
        }

    }, [isError, isLoading, isSuccess])



    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">

                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Brand Edit</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label htmlFor="product_name" className="form-label">Brnad Name</label>
                                <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                    defaultValue={data?.title}
                                    {...register("title")}
                                />
                                {errors?.title && (
                                    <span className="form__error">{errors?.title.message}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Brand Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                                {data?.images && <img style={{ width: '120px', height: '120px' }} src={data?.images} />
                                }
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                        </form>
                    </div>
                </section>

            </div>
        }
        </>
    )
}
export default BrandEdit