import React, { useEffect } from "react";
import demoImages from '../../../assets/imgs/items/1.jpg'
import { useDeletebrandMutation } from "../../../app/services/brand";
import { toast } from "react-toastify";
function SingleBrandItem({ data, setOpen }) {
    const [brandDeleteApi, { isSuccess, isLoading }] = useDeletebrandMutation()
    const brandDelete = async (id) => {
        await brandDeleteApi(id)
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Brand  Delete !')
        }
    }, [isSuccess])

    return (
        <tr >
            <td><img style={{ width: '60px', height: '60px', borderRadius: '100%' }} src={data?.images ? data?.images : demoImages} /></td>
            <td>{data?.title}</td>
            <td>0</td>
            <td className="text-end">
                <div className="text-end">
                    <a
                        onClick={() => setOpen({
                            type: true,
                            data: data
                        })}
                        className="btn btn-sm font-sm rounded btn-brand"> <i className="material-icons md-edit"></i> Edit </a>
                    <a style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} onClick={() => brandDelete(data?._id)}  className="btn btn-sm font-sm btn-light rounded"> <i className="material-icons md-delete_forever"></i> Delete </a>
                </div>
            </td>
        </tr>
    )
}
export default SingleBrandItem