import { Route, Routes, redirect, useNavigate } from 'react-router-dom';
import { AuthContext, ThemeContext } from './utils/Context';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Grid from './pages/products/Grid';
import Grid2 from './pages/products/Grid2';
import Categories from './pages/products/Categories';
import OrderDetail from './pages/order/OrderDetail';
import SellerCard from './pages/seller/SellerCard';
import SellerList from './pages/seller/SellerList';
import SellerProfile from './pages/seller/SellerProfile';
import SettingSample1 from './pages/Setting/SettingSample1';
import SettingSample2 from './pages/Setting/SettingSample2';
import Forbidden from './pages/Forbidden';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import AddProduct3 from './pages/products/AddProduct3';
import Transaction1 from './pages/transaction/Transaction1';
import Transaction2 from './pages/transaction/Transaction2';
import Brands from './pages/Brands';
import StarterPage from './pages/StarterPage';
import Reviews from './pages/Reviews';
import { ToastContainer } from 'react-toastify';
import ProtectedRoutes from './helpers/private/ProtectedRoutes';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/Firebase.config';
import Verified from './pages/auth/Verified';
import Spinner from './helpers/shared/Spinner';
import PublicRoutes from './helpers/public/PublicRoutes';
import Department from './pages/products/Department';
import SubCategories from './pages/products/SubCategories';
import ChildSubCategory from './pages/products/ChildSubCategory';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from './app/features/authSlice';
import { onAuthStateChanged } from 'firebase/auth';
import { decodeToken } from 'react-jwt';
import ProductEdit from './components/elements/product/ProductEdit';
import AllProductShow from './pages/products/AllProductList';
import Seo from './pages/Seo';
import Blog from './pages/Blog';
import UserAccountHandel from './pages/user/Users';
import UserInfoShow from './pages/UserInfoShow';
import Featured from './pages/products/productType/Featured';
import Popular from './pages/products/productType/Popular';
import TopSelling from './pages/products/productType/TopSelling';
import DealsOfDay from './pages/products/productType/DealsOfDay';
import Trending from './pages/products/productType/Trending';
import Topreted from './pages/products/productType/Topreted';
import Dailybestsells from './pages/products/productType/Dailybestsells';
import OrderWholesale from './pages/order/OrderWholesale';
import OrderRetailer from './pages/order/OrderRetailer';
import OrderConsumer from './pages/order/OrderConsumer';
import SalesWholesale from './pages/sales/SalesWholesale';
import SalesRetailer from './pages/sales/SalesRetailer';
import SalesConsumer from './pages/sales/SalesConsumer';
import SalesDetail from './pages/sales/SalesDetail';
import SalesRefundShrinkage from './pages/sales/SalesRefundShrinkage';

function App() {
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const [dark, setDark] = useState(false);
  const [handelClick, setSandelClick] = useState({
    zoom: {
      open: false
    },
    profile: {
      open: false
    }

  });

  useEffect(() => {
    const subscribed = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user?.getIdToken();
        const users = decodeToken(token);
        if (user?.accessToken) {
          dispatch(setToken({ user, users }))
        }
      }
    });
    return () => subscribed();
  }, [user, loading, dispatch])



  if (loading) {
    return (
      <Spinner />
    );
  }



  return (
    <AuthContext.Provider value={{ user, loading, error }}>
      <ThemeContext.Provider value={{ dark, setDark, handelClick, setSandelClick }}>
        <div className={`${dark ? 'dark' : ''}`}>
          <Routes>
            {
              user ? <Route path="" element={<ProtectedRoutes />}>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                {/* product */}
                <Route path="product">
                  <Route path="all" element={<AllProductShow />} />
                  <Route path="list/:id" element={<ProductEdit />} />
                  <Route path="grid" element={<Grid />} />
                  <Route path="grid2" element={<Grid2 />} />
                  <Route path="categorie" element={<Categories />} />
                  <Route path="department" element={<Department />} />
                  <Route path="subcategorie" element={<SubCategories />} />
                  <Route path="childsubcategory" element={<ChildSubCategory />} />
                </Route>
                {/* addProduct */}
                <Route path="addproduct">
                  <Route path="3" element={<AddProduct3 />} />
                </Route>
                {/* =================== product type ===================  */}
                <Route path="product">
                  <Route path="popular" element={<Popular />} />
                  <Route path="topselling" element={<TopSelling />} />
                  <Route path="trending-products" element={<Trending />} />
                  <Route path="featured" element={<Featured />} />
                  <Route path="dealsfotheday" element={<DealsOfDay />} />
                  <Route path="dailybestsells" element={<Dailybestsells />} />
                  <Route path="topreted" element={<Topreted />} />
                </Route>
                {/* order  */}
                <Route path='order'>
                  <Route path='wholesale' element={<OrderWholesale role='wholesale' />} />
                  <Route path='retailer' element={<OrderRetailer role='retailer' />} />
                  <Route path='consumer' element={<OrderConsumer role='consumer' />} />
                  <Route path='detail/:id' element={<OrderDetail />} />
                </Route>
                <Route path='sale'>
                  <Route path='wholesale' element={<SalesWholesale role='wholesale' />} />
                  <Route path='retailer' element={<SalesRetailer role='retailer' />} />
                  <Route path='consumer' element={<SalesConsumer role='consumer' />} />
                  <Route path='detail/:id' element={<SalesDetail />} />
                  <Route path='action/:id' element={<SalesRefundShrinkage/>} />
                </Route>
                {/* seller  */}
                <Route path='seller'>
                  <Route path='cards' element={<SellerCard />} />
                  <Route path='list' element={<SellerList />} />
                  <Route path='profile' element={<SellerProfile />} />
                </Route>
                {/* Transaction */}
                <Route path='transaction'>
                  <Route path='1' element={<Transaction1 />} />
                  <Route path='2' element={<Transaction2 />} />
                </Route>

                {/* settings  */}
                <Route path='settings'>
                  <Route path='sample1' element={<SettingSample1 />} />
                  <Route path='sample2' element={<SettingSample2 />} />
                </Route>
                {/* seo */}
                <Route path='seo' element={<Seo />} />
                {/* bolg pages  */}
                <Route path='blog' element={<Blog />} />
                {/* single */}
                <Route path='brands' element={<Brands />} />
                <Route path='reviews' element={<Reviews />} />
                <Route path='starterpage' element={<StarterPage />} />


                {/* User Handel  */}

                <Route path="user">
                  <Route path="wholesale" element={<UserAccountHandel role='wholesale' title='Wholesale' />} />
                  <Route path="retailer" element={<UserAccountHandel role='retailer' title='Retailer' />} />
                  <Route path="consumer" element={<UserAccountHandel role='consumer' title='Consumer' />} />

                </Route>
                <Route path="/user/:id" element={<UserInfoShow />} />

              </Route> : <Route path="" element={<PublicRoutes />}>
                <Route path='/' element={<Login />} />
                <Route path='login' element={<Login />} />
                <Route path='register' element={<Register />} />
              </Route>


            }
            {/* auth  */}
            <Route path='verifie' element={<Verified />} />
            <Route path='*' element={<Forbidden />} />
          </Routes>
        </div>
        <ToastContainer position="top-right" newestOnTop />
      </ThemeContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
