export const totalItemOrder = (data) => {
    let totalItems = 0;
    data?.selectFl?.forEach(fl => totalItems += fl?.item)
    return totalItems;
};


export const consumerPices = (fl_Item, deals, singlePrices) => {
    const quantity = fl_Item
    const { item, prices } = deals;
    if (item) {
        const discount = (singlePrices * item) - prices;
        const palanCount = quantity / item
        const stringValue = palanCount.toString();
        const parts = stringValue.split('.');
        const beforeDecimal = Number(parts[0]);
        const afterDecimal = Number(parts[1]);
        const afterPricesHandel = afterDecimal ? Number(`0.${afterDecimal}`) * item : 0
        const totalDiscount = discount * beforeDecimal;
        const totalPrices = (beforeDecimal * item) * singlePrices;
        const totalafterPrices = afterPricesHandel * singlePrices;
        const result = Number((totalPrices + totalafterPrices) - totalDiscount);
        return result.toFixed(2);
    } else {
        const totalPrices = Number(fl_Item * singlePrices)
        return totalPrices.toFixed(2)
    }
}
