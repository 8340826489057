import React, { useRef } from 'react'
import { BlogSchema } from '../../../../helpers/validation/Blog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import JoditEditor from "jodit-react";
import handel_Images from '../../HandelImages';
import { useAddBlogMutation } from '../../../../app/services/blog';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

function BlogModal({ modal, setOpen, categories }) {
    const editor = useRef(null);
    const { type } = modal;
    const [addBlog, { isError, isLoading, isSuccess }] = useAddBlogMutation()
    /* discetion handel */
    const [descriptionContent, setDescriptionContent] = useState('');
    const [productSlug, setProductSlug] = useState('');

    const contentFieldChanaged = (data) => {
        setDescriptionContent(data)
    }
    /* slug add */
    /*  ============== ======================= */
    const generateSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '');
    };
    const handleNameChange = (e) => {
        const newName = e.target.value;
        const newSlug = generateSlug(newName);
        setProductSlug(newSlug);
    };
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(BlogSchema) });

    const onSubmit = async (data) => {
        try {
            const images = data?.images;
            const response = await handel_Images(images)
            if (response?.data) {
                const setData = { title: data?.title, slug: productSlug, categorie_id: data?.categorie_id, images: response.data.secure_url, description: descriptionContent }
                await addBlog(setData)
            } else {
                const setData = { title: data?.title, slug: productSlug, categorie_id: data?.categorie_id, description: descriptionContent }
                await addBlog(setData)
            }
        } catch (error) {
            console.log("Error uploading image:", error);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Blog Add !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry Blog not add!')
        }

    }, [isError, isLoading, isSuccess])

    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main blog_handel">

                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Blog Add</h2>
                                <button onClick={() => setOpen({ type: false }, reset(),setProductSlug(''))}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row gx-2">
                                <div className="col-md-6">
                                    <label htmlFor="product_name" className="form-label">Title <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                        {...register("title")}
                                        onChange={handleNameChange}
                                    />
                                    {errors?.title && (
                                        <span className="form__error">{errors?.title.message}</span>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="slug" className="form-label">Slug <span style={{ color: 'red' }}>*</span></label>
                                    <input type="text" placeholder="slug" className="form-control" id="slug"
                                        value={productSlug}
                                        onChange={handleNameChange}
                                    />
                                    {errors?.slug && (
                                        <span className="form__error">{errors?.slug.message}</span>
                                    )}
                                </div>
                            </div>

                            <div className="mb-4">
                                <label className="form-label">Category</label>
                                <select className="form-select" {...register("categorie_id")} >
                                    <option className="form-label" value=''>Category Select</option>
                                    {categories?.categorie?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                                </select>
                                {errors?.categorie_id && (
                                    <span className="form__error">{errors?.categorie_id.message}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Blog Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor='description' className="form-label"> Description</label>
                                <JoditEditor
                                    ref={editor}
                                    onChange={(newContent) => contentFieldChanaged(newContent)}
                                />
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

                        </form>
                    </div>
                </section>

            </div>
        }
        </>
    )
}

export default BlogModal