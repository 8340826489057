export const SideMenu = [
    {
        id: 1,
        to: '/',
        link: "Dashboard",
        icon: "icon material-icons md-home",
        children: [
            {
                id: 1,
                _id: 1,
                to: 'https://invoice.zavawholesale.com/',
                link: "Invoice",
            },
            {
                id: 1,
                _id: 2,
                to: 'https://aizafgroup.web.app/dashboard',
                link: "Retwho",
            },
        ]
    },
    {
        id: 2,
        link: "Products",
        icon: "icon material-icons md-shopping_bag",
        children: [
            {
                id: 2,
                _id: 1,
                to: '/product/all',
                link: "All Product",
            },
            {
                id: 2,
                _id: 2,
                to: '/product/department',
                link: "Department",
            },
            {
                id: 2,
                _id: 3,
                to: '/product/categorie',
                link: "Categories",
            },
            {
                id: 2,
                _id: 4,
                to: '/product/subcategorie',
                link: "Sub Categorie",
            },
            {
                id: 2,
                _id: 5,
                to: '/product/childsubcategory',
                link: "Child Sub Category",
            },

        ]
    },
    {
        link: "Orders",
        id: 3,
        icon: "icon material-icons md-shopping_cart",
        children: [
            {
                id: 3,
                _id: 1,
                to: '/order/wholesale',
                link: "Wholesale",
            },
            {
                id: 3,
                _id: 2,
                to: '/order/retailer',
                link: "Retailer",
            },
            {
                id: 3,
                _id: 3,
                to: '/order/consumer',
                link: "Consumer",
            }
        ]

    },
    /*   {
          id:4,
  
          link: "Sellers",
          icon: "icon material-icons md-store",
          children: [
              {
                  id:4,
                  _id:1,
                  to: '/seller/cards',
                  link: "Sellers cards",
              },
              {
                  id:4,
                  _id:2,
                  to: '/seller/list',
                  link: "Sellers list",
              },
              {
                  id:4,
                  _id:3,
                  to: '/seller/profile',
                  link: "Sellers profile",
              }
          ]
      }, */
    {
        id: 5,

        link: "Add product",
        icon: "icon material-icons md-add_box",
        children: [
            {
                id: 5,
                _id: 3,
                to: '/addproduct/3',
                link: "Add product",
            },
        ]
    },
    {
        id: 6,
        link: "Product Type",
        icon: "icon material-icons md-monetization_on",
        children: [
            {
                id: 6,
                _id: 1,
                to: '/product/popular',
                link: "Popular",
            },
            {
                id: 6,
                _id: 2,
                to: '/product/topselling',
                link: "Top Selling",
            },
            {
                id: 6,
                _id: 3,
                to: '/product/trending-products',
                link: "Trending Products",
            },
            {
                id: 6,
                _id: 4,
                to: '/product/dealsfotheday',
                link: "Deals Of The Day",
            },
            {
                id: 6,
                _id: 5,
                to: '/product/featured',
                link: "Featured",
            },
            {
                id: 6,
                _id: 6,
                to: '/product/dailybestsells',
                link: "Daily Best Sells",
            },
            {
                id: 6,
                _id: 7,
                to: '/product/topreted',
                link: "Top Rated",
            },
        ]
    },
    {
        id: 8,
        to: '/brands',
        link: "Brands",
        icon: "icon material-icons md-stars",
    },

    {
        id: 8,
        to: '/blog',
        link: "Blog",
        icon: "icon material-icons md-stars",
    },
    {
        id: 12,
        to: '/seo',
        link: "SEO ",
        icon: "icon material-icons md-disc_full",
    },
    {
        id: 13,
        link: "User",
        icon: "icon material-icons md-person_remove",
        children: [
            {
                id: 13,
                _id: 1,
                to: '/user/wholesale',
                link: "Wholesale",
            },
            {
                id: 13,
                _id: 2,
                to: '/user/retailer',
                link: "Retailer",
            },
            {
                id: 13,
                _id: 3,
                to: '/user/consumer',
                link: "Consumer",
            }
        ]
    },
    {
        id: 14,
        link: "Sales",
        icon: "icon material-icons md-point_of_sale",
        children: [
            {
                id: 14,
                _id: 1,
                to: '/sale/wholesale',
                link: "Wholesale",
            },
            {
                id: 14,
                _id: 2,
                to: '/sale/retailer',
                link: "Retailer",
            },
            {
                id: 14,
                _id: 3,
                to: '/sale/consumer',
                link: "Consumer",
            }
        ]
    },


]
