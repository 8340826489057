import React, { useEffect, useState } from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import { SeoSchema } from '../helpers/validation/Seo.Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useGetSeoQuery, useUpdateSeoMutation } from '../app/services/seo';
import { toast } from 'react-toastify';
function Seo() {
  const { data } = useGetSeoQuery()
  const [updateSeo, { isSuccess, isError, isLoading: UpdateLoading }] = useUpdateSeoMutation()
  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(SeoSchema) });
  const onSubmit = async (datas) => {
    const value = {
      head: datas.head,
      body: datas.body,
      footer: datas.footer,
    }
    const updateValueSeo = { value, id: data?.seo?._id }
    await updateSeo(updateValueSeo)
  }
  useEffect(() => {
    if (isSuccess) {
      toast.success('Update Tag')
    }
  }, [isError, isSuccess])

  return (
    <DashboardLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-lg-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="mb-4">
                <label htmlFor='metadis' className="form-label">Head</label>
                <textarea placeholder="Meta Description" className="form-control"
                  {...register("head")}
                  defaultValue={data?.seo?.head}
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor='metadis' className="form-label">Body</label>
                <textarea placeholder="Meta Description" className="form-control"                
                  {...register("body")}
                  defaultValue={data?.seo?.body}
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor='metadis' className="form-label">Footer</label>
                <textarea placeholder="Meta Description" className="form-control"
                  {...register("footer")}
                  defaultValue={data?.seo?.footer}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <button style={{ cursor: UpdateLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

      </form>

    </DashboardLayout>
  )
}

export default Seo