import DashboardLayout from "../components/layout/DashboardLayout";
import { useEffect, useMemo, useState } from "react";
import { useGetDashboardQuery } from "../app/services/dashboard";
import DateCalendar from "../components/elements/DateCalendar";
import GetSpinner from "../helpers/shared/GetSpinner";

const Home = () => {
    const [Loading, setLoading] = useState(false)
    const [selectedDates, setSelectedDates] = useState([]);
    const date = `fromDate=${selectedDates[0]}&toDate=${selectedDates[1]}`;
    const { data: dashboardData, isLoading } = useGetDashboardQuery(date)
    const ProductData = useMemo(() => (dashboardData ? dashboardData?.products[0] : 0), [
        dashboardData,
    ]);

    const data = [
        {
            "name": "Jan",
            "uv": 4000,
            "pv": 2400,
            "amt": 0
        },
        {
            "name": "Feb",
            "uv": 3000,
            "pv": 1398,
            "amt": 5
        },
        {
            "name": "Mar",
            "uv": 2000,
            "pv": 9800,
            "amt": 10
        },
        {
            "name": "Apr",
            "uv": 2780,
            "pv": 3908,
            "amt": 15
        },
        {
            "name": "May",
            "uv": 1890,
            "pv": 4800,
            "amt": 20
        },
        {
            "name": "Jun",
            "uv": 2390,
            "pv": 3800,
            "amt": 25
        },
        {
            "name": "Jul",
            "uv": 3490,
            "pv": 4300,
            "amt": 30
        },
        {
            "name": "Aug",
            "uv": 3490,
            "pv": 4300,
            "amt": 35
        },
        {
            "name": "Sep",
            "uv": 3490,
            "pv": 4300,
            "amt": 40
        },
        {
            "name": "Oct",
            "uv": 3490,
            "pv": 4300,
            "amt": 40
        },
        {
            "name": "Dec",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
        },
    ]

    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        }
        if (dashboardData) {
            setLoading(false)
        }

    }, [isLoading, data])



    console.log(dashboardData)




    return (
        <DashboardLayout>
            {
                !dashboardData ? <GetSpinner /> : <>
                    <div className=" content-header">
                        <div>
                            <h2 className="content-title card-title">Dashboards</h2>
                            <p>Whole data about your business here</p>
                        </div>
                        <div>
                            <DateCalendar
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Total Purchase</h6>
                                        <span>${ProductData ? ProductData?.totalCost.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Cost of Goods</h6>
                                        <span>${ProductData ? ProductData?.cost.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Total Sold Quantity
                                        </h6>
                                        <span>{dashboardData?.sales[0]?.quantity > 0 ? dashboardData?.sales[0]?.quantity.toFixed(2) : 0}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-warning-light"><i className="text-warning material-icons md-qr_code"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">In Stock Box</h6>
                                        <span>{dashboardData?.products[0]?.quantity.toFixed(2)}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">In Stock Pcs</h6>
                                        <span>{dashboardData?.products[0]?.totalPcs.toFixed(2)}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i className="text-success material-icons md-local_shipping"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Total Sales</h6>
                                        <span>${dashboardData?.sales[0]?.total > 0 ? dashboardData?.sales[0]?.total.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i className="text-success material-icons md-local_shipping"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Offline Sales</h6>
                                        <span>${dashboardData?.dashboard?.oflineSales[0]?.offlineTotal > 0 ? dashboardData?.dashboard?.oflineSales[0]?.offlineTotal.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i className="text-success material-icons md-local_shipping"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Online Sales</h6>
                                        <span>${dashboardData?.dashboard?.onlineSale[0]?.onlineTotal > 0 ? dashboardData?.dashboard?.onlineSale[0]?.onlineTotal.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>

                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Orders</h6>
                                        <span>{dashboardData?.dashboard?.order_count > 0 ? dashboardData?.dashboard?.order_count.toFixed(2) : ''}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Expenses</h6>
                                        <span>0</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Due Inoivce</h6>
                                        <span>${dashboardData?.payment?.totalDue ? dashboardData?.payment?.totalDue?.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Paid Inoivce</h6>
                                        <span>${dashboardData?.payment?.totalDue ? dashboardData?.payment?.totalInvoic?.toFixed(2) : '0'}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Gross Profit</h6>
                                        <span>${dashboardData?.dashboard?.grossProfit ? dashboardData?.dashboard?.grossProfit.toFixed(2) : 0}</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Net Profit </h6>
                                        <span>0</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Refund
                                        </h6>
                                        <span>0</span>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card card-body mb-4">
                                <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i className="text-info material-icons md-shopping_basket"></i></span>
                                    <div className="text">
                                        <h6 className="mb-1 card-title">Shrinkage
                                        </h6>
                                        <span>0</span>
                                    </div>
                                </article>
                            </div>
                        </div>

                    </div>
                </>
            }

        </DashboardLayout>
    );
}

export default Home;