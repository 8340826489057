import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import { useParams } from 'react-router-dom';
import { useGetSingleUserQuery } from '../app/services/user';
import GetSpinner from '../helpers/shared/GetSpinner';

function UserInfoShow() {
    const { id } = useParams();
    const pathname = `_id=${id}`
    const { data } = useGetSingleUserQuery(pathname)

    return (
        <>
            <DashboardLayout>
                {
                    !data ? <GetSpinner /> :

                        <section className="content-main">
                            <div className="content-header">
                                <div>
                                    <h2 className="content-title card-title">{data?.company_name}</h2>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className='user_infomation_show'>
                                    <div>
                                        <p>Name : {data?.name}</p>
                                        <p>Last Name : {data?.last_name}</p>
                                        <p>Email : {data?.email}</p>
                                        <p>Nid : {data?.nid}</p>
                                        <p>Country : {data?.country}</p>
                                        <p>City : {data?.city}</p>
                                        <p>Zip Code : {data?.zip_code}</p>
                                        <p>State : {data?.state}</p>
                                        <p>Address : {data?.address}</p>
                                    </div>
                                    <div>
                                        <p>Company Name : <strong>{data?.company_name}</strong></p>
                                        <p>Company Email : {data?.company_email}</p>
                                        <p>Company Number : {data?.company_number}</p>
                                        <p>Tobacco : {data?.tobacco}</p>
                                        <p>Resale : {data?.resale}</p>
                                        <p>Fein : {data?.fein}</p>

                                    </div>
                                </div>

                            </div>
                        </section>
                }

            </DashboardLayout >
        </>
    )
}



export default UserInfoShow