import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../../utils/Context'
import { useSelector } from 'react-redux';
function ProtectedRoutes() {
  const location = useLocation();
  const {approved} = useSelector((state) => state.auth?.userInfo?.users)
  return approved ? (
    <Outlet />
  ) : (
    <Navigate to="verifie" state={{ from: location }} replace />
  );
}

export default ProtectedRoutes