import React, { } from 'react'


function Shrinkage({ shrinkageProduct, setShrinkageProduct }) {
    const { type, data } = shrinkageProduct;
    console.log('shrinkage', data)

    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Product Shrinkage</h2>
                                <button onClick={() => setShrinkageProduct({ type: false })}>X</button>
                            </div>
                        </div>
                    </div>
                    <a className="btn btn-sm font-sm rounded btn-brand"
                    >Shrinkage Save</a>
                </section>

            </div>
        }
        </>

    )
}

export default Shrinkage