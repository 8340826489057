import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductsSchema } from '../../helpers/validation/ProductsSchema';
import { useGetDepartmentQuery } from '../../app/services/department';
import { useQueryCategoriesMutation } from '../../app/services/categories';
import { useQuerySubCategoriesMutation } from '../../app/services/subCategories';
import { useQueryChildSubCategoriesMutation } from '../../app/services/childsubCategories';
import { useRef } from 'react';
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { useAddProductMutation, useAddbulkProductMutation } from '../../app/services/product';
import { toast } from 'react-toastify';
import { useGetAllbrandQuery } from '../../app/services/brand';


function AddProduct3() {
    const editor = useRef(null);
    const [sameWord, setSameWord] = useState('')
    const [content, setContent] = useState('');
    const [porductDetails, setPorductDetails] = useState('');
    const [productSlug, setProductSlug] = useState('');

    const generateSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with dashes
            .replace(/[^\w-]+/g, ''); // Remove non-word characters
    };

    /*  ============== ======================= */
    const handleNameChange = (e) => {
        const newName = e.target.value;
        const newSlug = generateSlug(newName);
        setProductSlug(newSlug);
    };
    /*  ============== ======================= */

    const contentFieldChanaged = (data) => {
        setContent(data)
    }
    const specificWordCount = (content.match(new RegExp(sameWord, 'gi')) || []).length;
    /* product ditils setPorductDetails */
    const PorductDetailscontentField = (data) => {
        setPorductDetails(data)
    }

    /*  product add  */
    const [AddProduct, { isError, isLoading, isSuccess }] = useAddProductMutation()
    const { data: brand } = useGetAllbrandQuery()

    /* deparment,category,sub,child =============== handel start */
    const { data } = useGetDepartmentQuery()
    const [QueryCategories, { data: categorieData }] = useQueryCategoriesMutation()
    const [QuerySubCategories, { data: subCategorieData }] = useQuerySubCategoriesMutation()
    const [QueryChildSub, { data: child }] = useQueryChildSubCategoriesMutation()
    const [AddbulkProduct, { isError: bulkisError, isLoading: bulkisLoading, isSuccess: bulkisSuccess }] = useAddbulkProductMutation()
    /*  select Department Query categoties sub child  */
    const selectDepartment = async (id) => {
        await QueryCategories(id)
    }
    const selectCategory = async (id) => {
        await QuerySubCategories(id)
    }
    const selectSubCategory = async (id) => {
        await QueryChildSub(id)
    }
    /* deparment,category,sub,child =============== handel end */

    const [flavior, setFlavior] = useState([{ name: '', image: [], imagesTitle: '', imageAlterText: '' }])
    /* =============================== selling price  hadnel =============================== */
    const [selling, setSelling] = useState()

    /*  image text  */
    const handleChangeText = (index, event) => {
        const value = event.target.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], name: value };
            return updatedFlavior;
        });
    }
    const handleImageTitle = (index, event) => {
        const value = event.target.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], imagesTitle: value };
            return updatedFlavior;
        });
    }
    const handleImageAlterText = (index, event) => {
        const value = event.target.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], imageAlterText: value };
            return updatedFlavior;
        });
    }
    const handleChangeImages = (image, index) => {
        if (image) {
            setFlavior(prevState => {
                const updatedFlavior = [...prevState];
                updatedFlavior[index] = { ...updatedFlavior[index], image: [...updatedFlavior[index].image, ...image] };
                return updatedFlavior;
            });
        }



    }

    /* single imges delete */
    const flaviorItemsDelete = (flaviorIndex, imageIndex) => {
        console.log(flaviorIndex)
        console.log(imageIndex)
        // setFlavior((prevState) => {
        //     const updatedFlavior = JSON.parse(JSON.stringify(prevState));
        //     updatedFlavior[flaviorIndex].image = updatedFlavior[flaviorIndex].image.filter(
        //         (_, i) => i !== imageIndex
        //     );
        //     return updatedFlavior;
        // });
    };


    /*  image hosting   */
    const handleFileChange = (e, index) => {
        const files = e.target.files;
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            const filename = uuidv4();
            formData.append('file', files[i]);
            formData.append('public_id', filename);
            formData.append('upload_preset', 'p36dm5k4');
            axios
                .post('https://api.cloudinary.com/v1_1/retwho/image/upload', formData)
                .then((response) => {
                    uploadedImages.push(response.data.secure_url);
                    if (uploadedImages.length === files.length) {
                        handleChangeImages(uploadedImages, index)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    /*  image remove   */
    const Deleteflavior = (index) => {
        setFlavior(flavior => flavior.filter((_, i) => i !== index));
    }


    /* hook  Deals handel */
    const [dealsRetailer, setDealsPcsRetailer] = useState([])
    const [dealsWholesaler, setDealsPcsWholesaler] = useState([])
    const [dealsConsumer, setDealsPcsConsumer] = useState([])


    /* ------------------ Retailer Deals ------------------ */
    const handleDealsRetailerPcsPrices = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleDealsRetailerItem = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }
    const DeleteRetailerDeals = (index) => {
        setDealsPcsRetailer(dealsRetailer => dealsRetailer.filter((_, i) => i !== index));
    }
    /* ------------------ Wholesaler Deals ------------------ */
    const handleDealsWholesalerPcsPrices = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleDealsWholesalerItem = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }
    const DeleteWholesalerDeals = (index) => {
        setDealsPcsRetailer(dealsWholesaler => dealsWholesaler.filter((_, i) => i !== index));
    }

    /* ------------------ Consumer Deals ------------------ */
    const handleDealsConsumerPcsPrices = (index, event) => {
        const value = event.target.value;
        setDealsPcsWholesaler(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleDealsConsumerItem = (index, event) => {
        const value = event.target.value;
        setDealsPcsWholesaler(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }
    const DeleteConsumerDeals = (index) => {
        setDealsPcsWholesaler(dealsConsumer => dealsConsumer.filter((_, i) => i !== index));
    }




    /*  product submit  */
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(ProductsSchema) });
    const onSubmit = async (data) => {

        const datas = {
            product_name: data?.product_name,
            upc: data?.upc,
            upcBox: data?.upcBox,
            sku: data?.sku,
            slug: productSlug,
            development_id: data?.development_id,
            categorie_id: data?.categorie_id,
            sub_id: data?.sub_id,
            childSub_id: data?.childSub_id,
            brand_id: data?.brand_id,
            status: data?.status,
            quantity: data?.quantity,
            images: flavior,
            saleing_Price: data?.saleing_Price,
            retailer_saleing_Price: data?.retailer_saleing_Price,
            wholesaler_saleing_Price: data?.wholesaler_saleing_Price,
            consumer_saleing_Price: data?.consumer_saleing_Price,
            cost: data?.cost,
            pcs: data?.pcs,
            price: [
                { name: 'xs', price: data?.xs },
                { name: 'sm', price: data?.sm },
                { name: 'md', price: data?.md },
                { name: 'lg', price: data?.lg },
                { name: 'xl', price: data?.xl },
                { name: 'xl_2', price: data?.xl_2 },
                { name: 'xl_3', price: data?.xl_3 },
                { name: 'xl_4 ', price: data?.xl_4 },
                { name: 'xl_5', price: data?.xl_5 },
                { name: 'xl_6', price: data?.xl_6 },
                { name: 'xl_7', price: data?.xl_7 },
            ],
            retailer_price: [
                { name: 'xs', price: data?.retailer_xs },
                { name: 'sm', price: data?.retailer_sm },
                { name: 'md', price: data?.retailer_md },
                { name: 'lg', price: data?.retailer_lg },
                { name: 'xl', price: data?.retailer_xl },
                { name: 'xl_2', price: data?.retailer_xl_2 },
                { name: 'xl_3', price: data?.retailer_xl_3 },
                { name: 'xl_4 ', price: data?.retailer_xl_4 },
                { name: 'xl_5', price: data?.retailer_xl_5 },
                { name: 'xl_6', price: data?.retailer_xl_6 },
                { name: 'xl_7', price: data?.retailer_xl_7 },
            ],
            wholesaler_price: [
                { name: 'xs', price: data?.wholesaler_xs },
                { name: 'sm', price: data?.wholesaler_sm },
                { name: 'md', price: data?.wholesaler_md },
                { name: 'lg', price: data?.wholesaler_lg },
                { name: 'xl', price: data?.wholesaler_xl },
                { name: 'xl_2', price: data?.wholesaler_xl_2 },
                { name: 'xl_3', price: data?.wholesaler_xl_3 },
                { name: 'xl_4 ', price: data?.wholesaler_xl_4 },
                { name: 'xl_5', price: data?.wholesaler_xl_5 },
                { name: 'xl_6', price: data?.wholesaler_xl_6 },
                { name: 'xl_7', price: data?.wholesaler_xl_7 },
            ],
            consumer_price: [
                { name: 'xs', price: data?.consumer_xs },
                { name: 'sm', price: data?.consumer_sm },
                { name: 'md', price: data?.consumer_md },
                { name: 'lg', price: data?.consumer_lg },
                { name: 'xl', price: data?.consumer_xl },
                { name: 'xl_2', price: data?.consumer_xl_2 },
                { name: 'xl_3', price: data?.consumer_xl_3 },
                { name: 'xl_4 ', price: data?.consumer_xl_4 },
                { name: 'xl_5', price: data?.consumer_xl_5 },
                { name: 'xl_6', price: data?.consumer_xl_6 },
                { name: 'xl_7', price: data?.consumer_xl_7 },
            ],
            tag: data?.tag,
            meta_title: data?.meta_title,
            meta_description: data?.meta_description,

            sale_retailer: [
                {
                    status: data?.retailer_sales_offer_status,
                    type: data?.retailer_sales_offer_type,
                    offer: data?.retailer_sales_offer_amount,
                    amount: data?.retailer_sales_offer_totatPrices,
                    color: data?.retailer_sales_offer_color,
                    content: data?.retailer_sales_offer_content,
                }
            ],
            retailer_deals: [
                {
                    title: data?.retailer_deals_title,
                    status: data?.retailer_deals_status,
                    items: dealsRetailer
                }
            ],
            sale_wholesaler: [
                {
                    status: data?.wholesaler_sales_offer_status,
                    type: data?.wholesaler_sales_offer_type,
                    offer: data?.wholesaler_sales_offer_amount,
                    amount: data?.wholesaler_sales_offer_totatPrices,
                    color: data?.wholesaler_sales_offer_color,
                    content: data?.wholesaler_sales_offer_content,
                }
            ],
            wholesaler_deals: [
                {
                    title: data?.wholesaler_deals_title,
                    status: data?.wholesaler_deals_status,
                    items: dealsWholesaler
                }
            ],


            sale_consumer: [
                {
                    status: data?.consumer_sales_offer_status,
                    type: data?.consumer_sales_offer_type,
                    offer: data?.consumer_sales_offer_amount,
                    amount: data?.consumer_sales_offer_totatPrices,
                    color: data?.consumer_sales_offer_color,
                    content: data?.consumer_sales_offer_content,
                }
            ],
            consumer_deals: [
                {
                    title: data?.consumer_deals_title,
                    status: data?.consumer_deals_status,
                    items: dealsConsumer,
                }
            ],

            coupon: [
                {
                    type: data?.coupon_offer_type,
                    offer: data?.coupon_offer,
                    amount: data?.coupon_Offer_amount,
                }
            ],
            promo: [
                {
                    type: data?.promo_offer_type,
                    offer: data?.promo_code,
                    amount: data?.promo_amount,
                }
            ],
            deal: [
                {
                    type: data?.deal_offer_type,
                    offer: data?.deal_time_type,
                    amount: data?.deal_amount,
                }
            ],
            description: content,
            product_ditails: porductDetails,
        }
        await AddProduct(datas)

    }
    /* Bullk product upload  */
    const handleBullkFileSelect = async (event) => {
        const files = event.target.files;
        const formData = new FormData();
        formData.append('product.csv', files[0])
        await AddbulkProduct(formData)
    };


    useEffect(() => {
        if (isSuccess || bulkisSuccess) {
            toast.success('Successful Product Add!')
            reset()
        }
        if (isError || bulkisError) {
            toast.error('Sorry Product Not Add!')
        }
    }, [isError, isLoading, isSuccess, bulkisError, bulkisLoading, bulkisSuccess])

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="row">
                    <div className="col-12">
                        <div className="content-header">
                            <h2 className="content-title">Add New Product </h2>
                            <div>
                                <button className="btn btn-md rounded font-sm hover-up"
                                    style={{ cursor: bulkisLoading ? 'no-drop' : 'pointer' }}
                                ><label htmlFor='BullkProduct'>Bullk Product</label></button>
                                <input style={{ display: 'none' }} id='BullkProduct' type='file' name="products_csv" accept=".csv, .txt, text/csv, text/plain"
                                    onChange={(event) => handleBullkFileSelect(event)}
                                />
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_title" className="form-label">Product Title <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" placeholder="Product Title" className="form-control" id="product_title" {...register("product_name")}
                                                onChange={handleNameChange}

                                            />
                                            {errors?.product_name && (
                                                <span className="form__error">{errors?.product_name.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="slug" className="form-label">Slug<span style={{ color: 'red' }}> *</span></label>
                                            <input type="text" placeholder="Slug" className="form-control" id="slug"
                                                value={productSlug}


                                            />
                                            {errors?.slug && (
                                                <span className="form__error">{errors?.slug.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_UPC" className="form-label">UPC (Single)</label>
                                            <input type="text" placeholder="UPC" className="form-control" id="product_UPC"
                                                {...register("upc")}
                                            />
                                            {errors?.upc && (
                                                <span className="form__error">{errors?.upc.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_SKU" className="form-label">SKU</label>
                                            <input type="text" placeholder="SKU" className="form-control" id="product_SKU"
                                                {...register("sku")} />
                                            {errors?.sku && (
                                                <span className="form__error">{errors?.sku.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_upc_box" className="form-label">UPC (Box) </label>
                                            <input type="text" placeholder="UPC (Box)" className="form-control" id="product_upc_box"
                                                {...register("upcBox")}
                                            />
                                            {errors?.upcBox && (
                                                <span className="form__error">{errors?.upcBox.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Department</label>
                                                <select className="form-select" {...register("development_id")} onChange={(e) => selectDepartment(e.target.value)}>
                                                    <option value=''>Department Select</option>
                                                    {data?.development?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                                                </select>
                                                {errors?.development_id && (
                                                    <span className="form__error">{errors?.development_id.message}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Category</label>
                                                <select className="form-select" {...register("categorie_id")} onChange={(e) => selectCategory(e.target.value)}>
                                                    <option className="form-label" value=''>Category Select</option>
                                                    {categorieData?.categorie?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label" >Sub Category</label>
                                                <select className="form-select" {...register("sub_id")} onChange={(e) => selectSubCategory(e.target.value)}>
                                                    <option className="form-label" value=''>Sub Category Select</option>
                                                    {subCategorieData?.subCategorie?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label" value=''>Child Sub Category</label>
                                                <select className="form-select" {...register("childSub_id")}>
                                                    <option className="form-label" value=''>Child Sub Select</option>
                                                    {child?.childSub?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Brand</label>
                                                {/* brand */}
                                                <select className="form-select" {...register("brand_id")}>
                                                    <option className="form-label" value=''>Brand Select</option>
                                                    {brand?.map(data => <option value={data?._id} key={data?._id}>{data?.title}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Status</label>
                                                <select className="form-select" {...register("status")}>
                                                    <option value={true}>active</option>
                                                    <option value={false}>inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_Quantity" className="form-label">Quantity<span style={{ color: 'red' }}>*</span></label>
                                            <input type="number" placeholder="product_Quantity" className="form-control" id="product_Quantity"
                                                {...register("quantity")}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_cost" className="form-label">Pcs<span style={{ color: 'red' }}>*</span></label>
                                            <input type="number" placeholder="product_cost" className="form-control" id="product_cost"
                                                {...register("pcs")}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_cost" className="form-label">Cost<span style={{ color: 'red' }}>*</span></label>
                                            <input type="nubmer" step="any" placeholder="product_cost" className="form-control" id="product_cost"
                                                {...register("cost")}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h1 className='m-0 p-0 text-end px-4'><span onClick={() => setFlavior([...flavior, { name: '', image: [] }])} style={{ cursor: 'pointer' }}>+</span></h1>
                                <div className="card-body">
                                    {
                                        flavior.map((data, index) =>
                                            <div>
                                                <div key={index} className="row gx-3">
                                                    <div className="col-md-5 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Flavior Name</label>
                                                        <input onBlur={(event) => handleChangeText(index, event)} type="text" placeholder="Flavior Name" className="form-control" id="product_Flavior"
                                                        />
                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <label htmlFor="product_images" className="form-label">Images</label>
                                                        <input type="file" placeholder="Images" className="form-control" id="product_images"
                                                            onChange={(e) => handleFileChange(e, index)}
                                                            accept="image/jpeg,image/gif,image/png,image/webp"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                        <a className="form-control btn  font-sm btn-light rounded" onClick={() => Deleteflavior(index)}><i class="material-icons md-delete_forever"></i> Delete</a>

                                                    </div>


                                                    <div className='main_single_images_flex'>
                                                        {
                                                            data?.image?.map((value, imageindex) => <Fragment key={imageindex}>
                                                                <div className='single_images_delete'>
                                                                    <img className="img-sm img-thumbnail" alt="Item" src={value} />
                                                                    <span
                                                                        onClick={() => flaviorItemsDelete(data, imageindex)}
                                                                    ><i className="material-icons md-delete_forever"></i></span>
                                                                </div>
                                                            </Fragment>

                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row gx-2">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="images-title" className="form-label" >Image Title</label>
                                                        <input type="text" placeholder="Image Title" className="form-control" id="images-title"
                                                            onBlur={(event) => handleImageTitle(index, event)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="images-title-alter-text" className="form-label">Image Alter Text</label>
                                                        <input type="text" placeholder="Image Alter Text" className="form-control" id="images-title-alter-text"
                                                            onBlur={(event) => handleImageAlterText(index, event)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor='description' className="form-label"> Product Details</label>
                                <JoditEditor
                                    ref={editor}
                                    onChange={(newContent) => PorductDetailscontentField(newContent)}
                                />
                            </div>
                        </div>
                        {/* Offline Prices  */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Offline Prices</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="Saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                            <input text="text" placeholder="Saleing Price" className="form-control" id="Saleing_Price"
                                                {...register("saleing_Price")}
                                                onChange={(e) => setSelling(e.target.value)}

                                            />
                                            {errors?.saleing_Price && (
                                                <span className="form__error">{errors?.saleing_Price.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="price-xs" className="form-label" >Price xs </label>
                                            <input text="text" placeholder="sm" className="form-control" id="price-xs"
                                                {...register("xs")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="price-sm" className="form-label" >Price sm</label>
                                            <input text="text" placeholder="sm" className="form-control" id="price-sm"
                                                {...register("sm")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_md" className="form-label">Price md</label>
                                            <input text="text" placeholder="md" className="form-control" id="product_md"
                                                {...register("md")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_lg" className="form-label">Price lg</label>
                                            <input text="text" placeholder="lg" className="form-control" id="product_lg"
                                                {...register("lg")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_xl" className="form-label">Price xl</label>
                                            <input text="text" placeholder="xl" className="form-control" id="product_xl"
                                                {...register("xl")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_2xl" className="form-label">Price 2xl</label>
                                            <input text="text" placeholder="2xl" className="form-control" id="product_2xl"
                                                {...register("xl_2")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_3xl" className="form-label">Price 3xl</label>
                                            <input text="text" placeholder="3xl" className="form-control" id="product_3xl"
                                                {...register("xl_3")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_4xl" className="form-label">Price 4xl</label>
                                            <input text="text" placeholder="4xl" className="form-control" id="product_4xl"
                                                {...register("xl_4")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_5xl" className="form-label">Price 5xl</label>
                                            <input text="text" placeholder="5xl" className="form-control" id="product_5xl"
                                                {...register("xl_5")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_6xl" className="form-label">Price 6xl</label>
                                            <input text="text" placeholder="6xl" className="form-control" id="product_6xl"
                                                {...register("xl_6")}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_7xl" className="form-label">Price 7xl</label>
                                            <input text="text" placeholder="7xl" className="form-control" id="product_7xl"
                                                {...register("xl_7")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Retailer*/}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Retailer</h3>
                                {/* reguler prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Regular Prices:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="Saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input text="text" placeholder="Saleing Price" className="form-control" id="retailer_saleing_Price"
                                                    {...register("retailer_saleing_Price")}

                                                />
                                                {errors?.retailer_saleing_Price && (
                                                    <span className="form__error">{errors?.retailer_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_price-xs" className="form-label" >Price xs </label>
                                                <input text="text" placeholder="sm" className="form-control" id="retailer_price-xs"
                                                    {...register("retailer_xs")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_price-sm" className="form-label" >Price sm</label>
                                                <input text="text" placeholder="sm" className="form-control" id="retailer_price-sm"
                                                    {...register("retailer_sm")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_md" className="form-label">Price md</label>
                                                <input text="text" placeholder="md" className="form-control" id="retailer_product_md"
                                                    {...register("retailer_md")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_lg" className="form-label">Price lg</label>
                                                <input text="text" placeholder="lg" className="form-control" id="retailer_product_lg"
                                                    {...register("retailer_lg")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_xl" className="form-label">Price xl</label>
                                                <input text="text" placeholder="xl" className="form-control" id="retailer_product_xl"
                                                    {...register("retailer_xl")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_2xl" className="form-label">Price 2xl</label>
                                                <input text="text" placeholder="2xl" className="form-control" id="retailer_product_2xl"
                                                    {...register("retailer_xl_2")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_3xl" className="form-label">Price 3xl</label>
                                                <input text="text" placeholder="3xl" className="form-control" id="retailer_product_3xl"
                                                    {...register("retailer_xl_3")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_4xl" className="form-label">Price 4xl</label>
                                                <input text="text" placeholder="4xl" className="form-control" id="retailer_product_4xl"
                                                    {...register("retailer_xl_4")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_5xl" className="form-label">Price 5xl</label>
                                                <input text="text" placeholder="5xl" className="form-control" id="retailer_product_5xl"
                                                    {...register("retailer_xl_5")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_6xl" className="form-label">Price 6xl</label>
                                                <input text="text" placeholder="6xl" className="form-control" id="retailer_product_6xl"
                                                    {...register("retailer_xl_6")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_7xl" className="form-label">Price 7xl</label>
                                                <input text="text" placeholder="7xl" className="form-control" id="retailer_product_7xl"
                                                    {...register("retailer_xl_7")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("retailer_sales_offer_status")}
                                                    >
                                                        <option value={false} selected>active</option>
                                                        <option value={true}>inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("retailer_sales_offer_type")}
                                                    >
                                                        <option value={`$`} selected>$</option>
                                                        <option value={`%`}>%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("retailer_sales_offer_amount")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("retailer_sales_offer_totatPrices")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferconten" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Content" className="form-control"
                                                    id="saleofferconten"
                                                    {...register("retailer_sales_offer_content")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferColor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleofferColor"
                                                    {...register("retailer_sales_offer_color")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsRetailer([...dealsRetailer, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("retailer_deals_title")}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("retailer_deals_status")}
                                                            >
                                                                <option value={false} selected>active</option>
                                                                <option value={true}>inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsRetailer?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleDealsRetailerItem(index, event)}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsRetailerPcsPrices(index, event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteRetailerDeals(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>

                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Wholesaler */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Wholesaler</h3>
                                {/* reguler prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Wholesaler Prices:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_Saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input text="text" placeholder="Saleing Price" className="form-control" id="wholesaler_Saleing_Price"
                                                    {...register("wholesaler_saleing_Price")}

                                                />
                                                {errors?.wholesaler_saleing_Price && (
                                                    <span className="form__error">{errors?.wholesaler_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_price-xs" className="form-label" >Price xs </label>
                                                <input text="text" placeholder="sm" className="form-control" id="wholesaler_price-xs"
                                                    {...register("wholesaler_xs")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_price-sm" className="form-label" >Price sm</label>
                                                <input text="text" placeholder="sm" className="form-control" id="wholesaler_price-sm"
                                                    {...register("wholesaler_sm")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_md" className="form-label">Price md</label>
                                                <input text="text" placeholder="md" className="form-control" id="wholesaler_product_md"
                                                    {...register("wholesaler_md")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_lg" className="form-label">Price lg</label>
                                                <input text="text" placeholder="lg" className="form-control" id="wholesaler_product_lg"
                                                    {...register("wholesaler_lg")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_xl" className="form-label">Price xl</label>
                                                <input text="text" placeholder="xl" className="form-control" id="wholesaler_product_xl"
                                                    {...register("wholesaler_xl")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_2xl" className="form-label">Price 2xl</label>
                                                <input text="text" placeholder="2xl" className="form-control" id="wholesaler_product_2xl"
                                                    {...register("wholesaler_xl_2")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_3xl" className="form-label">Price 3xl</label>
                                                <input text="text" placeholder="3xl" className="form-control" id="wholesaler_product_3xl"
                                                    {...register("wholesaler_xl_3")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_4xl" className="form-label">Price 4xl</label>
                                                <input text="text" placeholder="4xl" className="form-control" id="wholesaler_product_4xl"
                                                    {...register("wholesaler_xl_4")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_5xl" className="form-label">Price 5xl</label>
                                                <input text="text" placeholder="5xl" className="form-control" id="wholesaler_product_5xl"
                                                    {...register("wholesaler_xl_5")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_6xl" className="form-label">Price 6xl</label>
                                                <input text="text" placeholder="6xl" className="form-control" id="wholesaler_product_6xl"
                                                    {...register("wholesaler_xl_6")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_7xl" className="form-label">Price 7xl</label>
                                                <input text="text" placeholder="7xl" className="form-control" id="wholesaler_product_7xl"
                                                    {...register("wholesaler_xl_7")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("wholesaler_sales_offer_status")}
                                                    >
                                                        <option value={false} selected>active</option>
                                                        <option value={true}>inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("wholesaler_sales_offer_type")}
                                                    >
                                                        <option value={`$`} selected>$</option>
                                                        <option value={`%`}>%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("wholesaler_sales_offer_amount")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("wholesaler_sales_offer_totatPrices")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferconten" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Content" className="form-control"
                                                    id="saleofferconten"
                                                    {...register("wholesaler_sales_offer_content")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferColor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleofferColor"
                                                    {...register("wholesaler_sales_offer_color")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsWholesaler([...dealsWholesaler, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("wholesaler_deals_title")}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("wholesaler_deals_status")}
                                                            >
                                                                <option value={false} selected>active</option>
                                                                <option value={true}>inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsWholesaler?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleDealsWholesalerItem(index, event)}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsWholesalerPcsPrices(index, event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteWholesalerDeals(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>

                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Consumer */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Consumer</h3>
                                {/* reguler prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Consumer Prices:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Saleing Price" className="form-control" id="consumer_saleing_Price"
                                                    {...register("consumer_saleing_Price")}

                                                />
                                                {errors?.consumer_saleing_Price && (
                                                    <span className="form__error">{errors?.consumer_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_price-xs" className="form-label" >Price xs </label>
                                                <input text="text" placeholder="sm" className="form-control" id="consumer_price-xs"
                                                    {...register("consumer_xs")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_price-sm" className="form-label" >Price sm</label>
                                                <input text="text" placeholder="sm" className="form-control" id="consumer_price-sm"
                                                    {...register("consumer_sm")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_md" className="form-label">Price md</label>
                                                <input text="text" placeholder="md" className="form-control" id="consumer_product_md"
                                                    {...register("consumer_md")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_lg" className="form-label">Price lg</label>
                                                <input text="text" placeholder="lg" className="form-control" id="consumer_product_lg"
                                                    {...register("consumer_lg")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_xl" className="form-label">Price xl</label>
                                                <input text="text" placeholder="xl" className="form-control" id="consumer_product_xl"
                                                    {...register("consumer_xl")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_2xl" className="form-label">Price 2xl</label>
                                                <input text="text" placeholder="2xl" className="form-control" id="consumer_product_2xl"
                                                    {...register("consumer_xl_2")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_3xl" className="form-label">Price 3xl</label>
                                                <input text="text" placeholder="3xl" className="form-control" id="consumer_product_3xl"
                                                    {...register("consumer_xl_3")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_4xl" className="form-label">Price 4xl</label>
                                                <input text="text" placeholder="4xl" className="form-control" id="consumer_product_4xl"
                                                    {...register("consumer_xl_4")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_5xl" className="form-label">Price 5xl</label>
                                                <input text="text" placeholder="5xl" className="form-control" id="consumer_product_5xl"
                                                    {...register("consumer_xl_5")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_6xl" className="form-label">Price 6xl</label>
                                                <input text="text" placeholder="6xl" className="form-control" id="consumer_product_6xl"
                                                    {...register("consumer_xl_6")}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_7xl" className="form-label">Price 7xl</label>
                                                <input text="text" placeholder="7xl" className="form-control" id="consumer_product_7xl"
                                                    {...register("consumer_xl_7")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("consumer_sales_offer_status")}
                                                    >
                                                        <option value={false} selected>active</option>
                                                        <option value={true}>inactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("consumer_sales_offer_type")}
                                                    >
                                                        <option value={`$`} selected>$</option>
                                                        <option value={`%`}>%</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("consumer_sales_offer_amount")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("consumer_sales_offer_totatPrices")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferconten" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Content" className="form-control"
                                                    id="saleofferconten"
                                                    {...register("consumer_sales_offer_content")}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferColor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleofferColor"
                                                    {...register("consumer_sales_offer_color")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsConsumer([...dealsConsumer, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("consumer_deals_title")}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("consumer_deals_status")}
                                                            >
                                                                <option value={false} selected>active</option>
                                                                <option value={true}>inactive</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsConsumer?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleDealsConsumerItem(index, event)}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsConsumerPcsPrices(index, event)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteConsumerDeals(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>
                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_tag" className="form-label" >Tag</label>
                                            <input type="text" placeholder="Tag" className="form-control" id="product_tag"
                                                {...register("tag")}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_meta-title" className="form-label">Meta Title</label>
                                            <input type="text" placeholder="Meta Title" className="form-control" id="product_meta-title"
                                                {...register("meta_title")}

                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor='metadis' className="form-label">Meta Description</label>
                                        <textarea placeholder="Meta Description" className="form-control" rows="4" id='metadis'
                                            {...register("meta_description")}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Coupon</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Coupon Offer Type</label>
                                                <select className="form-select"
                                                    {...register("coupon_offer_type")}
                                                >
                                                    <option value='$' selected>$</option>
                                                    <option value='%'>%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_coupon" className="form-label">Coupon Offer</label>
                                            <select className="form-select"
                                                {...register("coupon_offer")}
                                            >
                                                <option value={false} selected>$</option>
                                                <option value={true}>%</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="coupon_amount" className="form-label"> Offer amount </label>
                                            <input text="text"  {...register("coupon_Offer_amount")}
                                                className="form-control"
                                                id="coupon_amount"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Promo Code</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Promo Offer Type</label>
                                                <select className="form-select"
                                                    {...register("promo_offer_type")}
                                                >
                                                    <option value='$' selected>$</option>
                                                    <option value='%'>%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_amount" className="form-label">Promo Code</label>
                                            <input text="text" placeholder="Sale Offer" className="form-control" id="Promo_amount"
                                                {...register("promo_code")}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_Offer_Price" className="form-label">Offer amount</label>
                                            <input text="text" className="form-control" id="Promo_Offer_Price"
                                                {...register("promo_amount")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Deal</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Deal Offer Type</label>
                                                <select className="form-select"
                                                    {...register("deal_offer_type")}
                                                >
                                                    <option value='$' selected>$</option>
                                                    <option value='%'>%</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_amount" className="form-label">Deal Time</label>
                                            <select className="form-select"
                                                {...register("deal_time_type")}
                                            >
                                                <option value='day' selected>day</option>
                                                <option value='weekday'>week</option>
                                                <option value='month'>month</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_Offer_Price" className="form-label">Offer amount</label>
                                            <input text="text" className="form-control" id="Promo_Offer_Price"
                                                {...register("deal_amount")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">
                            <div className="mb-4">
                                <label className="form-label">keyword Counter</label>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'

                                }}>
                                    <input type="text"
                                        style={{ backgroundColor: 'white' }}
                                        className="form-control"
                                        onChange={(e) => setSameWord(e.target.value)}
                                    />
                                    {sameWord && specificWordCount}
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor='description' className="form-label"> Description</label>
                            <JoditEditor
                                ref={editor}
                                onChange={(newContent) => contentFieldChanaged(newContent)}
                            />
                        </div>
                        <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                    </form>
                </div>
            </section>

        </DashboardLayout >
    )
}

export default AddProduct3