import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// users service
export const SeoApi = createApi({
    reducerPath: "seo",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin-zavaus-com.vercel.app/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* get seo */
        getSeo: builder.query({
            query: () => ({
                url: `seo`,
                method: "GET",
            }),
            providesTags: ['Seo']
        }),
        /* =================== Update ===================== */
        updateSeo: builder.mutation({
            query: ({ value, id }) => ({
                url: `seo?_id=${id}`,
                method: "PUT",
                body: value,
            }),
            invalidatesTags: ["Seo"],
        }),



    }),
});
export const { useGetSeoQuery, useUpdateSeoMutation } = SeoApi;

