import React, { useEffect } from 'react'
import { CategoriesSchema } from '../../../../helpers/validation/CategoriesSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAddCategoriesMutation } from '../../../../app/services/categories';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function CaregorieModal({ modal, setOpen, data }) {
  const { type } = modal;
  const [AddCategories, { isError, isLoading, isSuccess }] = useAddCategoriesMutation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(CategoriesSchema) });
  const onSubmit = async (value) => {
    const images = value?.images;
    const response = await handel_Images(images)
    if (response) {
      const updateValue = { development_id: value?.development_id, name: value?.name, images: response?.data?.secure_url }
      await AddCategories(updateValue)
    } else {
      const updateValue = { development_id: value?.development_id, name: value?.name, images: data?.images }
      await AddCategories(updateValue)
    }
  }
  useEffect(() => {
    if (isSuccess) {
      toast.success('Caregorie Add !')
      setOpen({ type: false })
      reset()
    }
    if (isError) {
      toast.error('sorry Caregorie not add!')
    }

  }, [isError, isLoading, isSuccess])

  return (
    <>{
      type && <div className='modal_product'>
        <section className="content-main">

          <div className="row">
            <div className="col-12">
              <div className="content-header">
                <h2 className="content-title">Categorie Add</h2>
                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label className="form-label">Department</label>
                <select className="form-select"  {...register("development_id")}>
                  <option selected>Select Department</option>
                  {data?.development?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                </select>
                {errors?.development_id && (
                  <span className="form__error">{errors?.development_id.message}</span>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="product_name" className="form-label">Category Name</label>
                <input type="text" placeholder="Type here" className="form-control" id="product_name"
                  {...register("name")}
                />
                {errors?.name && (
                  <span className="form__error">{errors?.name.message}</span>
                )}
              </div>
              {/* images handel  */}
              <div className="mb-4">
                <label htmlFor="images" className="form-label">Category Images</label>
                <input type="file" placeholder="Type here" className="form-control" id="images"
                  {...register("images")}
                />
                {errors?.images && (
                  <span className="form__error">{errors?.images.message}</span>
                )}
              </div>
              <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
            </form>
          </div>
        </section >

      </div >
    }
    </>
  )
}

export default CaregorieModal