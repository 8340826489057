import React, { useMemo, useState } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import SingleProductCaregorie from '../../components/elements/product/SingleProductCaregorie'
import { useGetDepartmentQuery } from '../../app/services/department'
import { useGetCategoriesQuery, useQueryCategoriesMutation } from '../../app/services/categories'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChildSubCategorySchema } from '../../helpers/validation/ChildSubCategorySchema'
import { useGetSubCategoriesQuery, useQuerySubCategoriesMutation } from '../../app/services/subCategories'
import { useAddChildSubCategoriesMutation, useGetChildSubCategoriesQuery } from '../../app/services/childsubCategories'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import SingleChildSub from '../../components/elements/product/SingleChildSub'
import ChildSubCategories from '../../components/elements/modal/product/ChildSubCategories'
import GetSpinner from '../../helpers/shared/GetSpinner'
import ChildSubCategoriesEdit from '../../components/elements/modal/product/ChildSubCategoriesEdit'

function ChildSubCategory() {
    /* useUpdateChildSubCategoriesMutation */
    const [Loading, setLoading] = useState(false)
    const { data: department } = useGetDepartmentQuery()
    const { data: categorieAllGet } = useGetCategoriesQuery()
    const { data: SubCategorieALLGet } = useGetSubCategoriesQuery()

    const [QueryCategories, { data: categories }] = useQueryCategoriesMutation()
    const [QuerySubCategories, { data: subCategories }] = useQuerySubCategoriesMutation()
    const { data: child, isLoading } = useGetChildSubCategoriesQuery()
    /*  selectQuery Helper*/
    const selectDepartment = async (id) => {
        await QueryCategories(id)
    }
    const selectCategory = async (id) => {
        await QuerySubCategories(id)
    }

    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])

    /*  handel categorie and subCategorie categorie*/
    const categorie = useMemo(() => (categories ? categories?.categorie : categorieAllGet?.categorie), [
        categories,
        categorieAllGet
    ]);
    const subCategorie = useMemo(() => (subCategories ? subCategories?.subCategorie : SubCategorieALLGet?.subCategorie), [
        subCategories,
        SubCategorieALLGet
    ]);



    /*  modal section ==============  */
    const [childCreateModal, setChildCreateModal] = useState({ type: false, data: null })
    const [childEditModal, setChildEditModal] = useState({ type: false, data: null })
    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Child Sub Categorie</h2>
                        <p>Add, edit or delete a Child Sub Categorie</p>
                    </div>
                    <div>
                        <a
                            onClick={() => setChildCreateModal({ type: !childCreateModal.type })}
                            className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Sub Create Categories</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Images</th>
                                                <th>Child Sub </th>
                                                <th>Sub Categorie</th>
                                                <th>Categorie</th>
                                                <th>Department</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading && <GetSpinner />}
                                            {child?.childSub?.map(data => <SingleChildSub data={data} key={data?._id}
                                                setOpen={setChildEditModal}
                                            />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* create geteroty  */}
            <ChildSubCategories
                modal={childCreateModal}
                setOpen={setChildCreateModal}
                department={department}
                categorie={categorie}
                subCategorie={subCategorie}
                selectDepartment={selectDepartment}
                selectCategory={selectCategory}
            />
            <ChildSubCategoriesEdit
                modal={childEditModal}
                setOpen={setChildEditModal}
                department={department}
                categorie={categorie}
                subCategorie={subCategorie}
                selectDepartment={selectDepartment}
                selectCategory={selectCategory}
            />



        </DashboardLayout>
    )
}

export default ChildSubCategory