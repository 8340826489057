import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// users service
export const ProductApi = createApi({
    reducerPath: "product",
    tagTypes: ['Product','TypeDelete'],
    baseQuery: fetchBaseQuery({
        baseUrl: "https://backmin-zavaus-com.vercel.app/",
        prepareHeaders: (headers, { getState }) => {
            const { userInfo: user } = getState().auth;
            if (user?.user?.accessToken) {
                headers.set("authorization", `Bearer ${user?.user?.accessToken}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        /* ===========  add product ===========  */
        getproduct: builder.query({
            query: (pathname) => ({
                url: `product?${pathname}`,
                method: "GET",
            }),
            providesTags: ['Product']
        }),
        getSingleproduct: builder.query({
            query: (id) => ({
                url: `product?_id=${id}`,
                method: "GET",
            }),
            providesTags: ['Product']
        }),
        updateProductType: builder.mutation({
            query: (typeInfo) => ({
                url: `product/type?_id=${typeInfo?._id}`,
                method: "PUT",
                body: typeInfo,
            }),
            invalidatesTags: ["Product","TypeDelete"],
        }),

        /* porduct hype handel */
        getPopularProduct: builder.query({
            query: () => ({
                url: `product/type/popular`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getTopSellingProduct: builder.query({
            query: () => ({
                url: `product/type/topSelling`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getTrendingProduct: builder.query({
            query: () => ({
                url: `product/type/trending`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getDealsofdayProduct: builder.query({
            query: () => ({
                url: `product/type/dealsofday`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getFeaturedProduct: builder.query({
            query: () => ({
                url: `product/type/featured`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getRatedProduct: builder.query({
            query: () => ({
                url: `product/type/toprated`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),
        getDailybestsellsProduct: builder.query({
            query: () => ({
                url: `product/type/dailybestsells`,
                method: "GET",
            }),
            providesTags: ["Product","TypeDelete"],
        }),

        addProduct: builder.mutation({
            query: (product) => ({
                url: "product",
                method: "POST",
                body: product,
            }),
            invalidatesTags: ["Product"],
        }),
        addbulkProduct: builder.mutation({
            query: (product) => ({
                url: "product/bulk",
                method: "POST",
                body: product,
            }),
            invalidatesTags: ["Product"],
        }),
        queryProduct: builder.mutation({
            query: (product) => ({
                url: `product?_id=${product}`,
                method: "GET",
            }),
            invalidatesTags: ["Product"],
        }),
        /* =================== Delete ===================== */
        deleteProduct: builder.mutation({
            query: (product) => ({
                url: `product?_id=${product}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Product"],
        }),
        /* =================== Update ===================== */
        updateProduct: builder.mutation({
            query: ({ _id, updateData }) => ({
                url: `product?_id=${_id}`,
                method: "PUT",
                body: updateData,
            }),
            invalidatesTags: ["Product"],
        }),
        /* Extra product add  */
        productExtraCost: builder.mutation({
            query: (data) => ({
                url: "product/purchases",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Product"],
        }),
    }),
});
export const { useAddProductMutation, useGetproductQuery, useAddbulkProductMutation, useDeleteProductMutation, useGetSingleproductQuery, useUpdateProductMutation, useProductExtraCostMutation, useUpdateProductTypeMutation,useGetDealsofdayProductQuery,useGetFeaturedProductQuery,useGetPopularProductQuery,useGetTopSellingProductQuery,useGetTrendingProductQuery,useGetDailybestsellsProductQuery,useGetRatedProductQuery
} = ProductApi;

