import * as yup from "yup";
export const DepartmentSchema = yup.object().shape({
  department: yup.string()
    .required("Department field can not be empty.")
    .min(2, "Department must contain at least 6 characters."),
    images: yup.mixed().test("file", "You need to provide a file", (value) => {
      if (value.length > 0) {
          return true;
      }
      return true;
  }),
})
