import React, { } from 'react'
import { consumerPices, selectFlItemCounter } from '../../utils/shopCart'


function ViewRefundShrinkage({ data, userRole, setShrinkageProduct, setRefundProduct }) {

    return (
        <tr>
            <td>{data?.product_name.toUpperCase()}</td>
            <td>${data?.singlePrices?.toFixed(2)}</td>
            <td>
                <span>{selectFlItemCounter(data?.selectFl)}</span>
            </td>
            <td>
                <span>
                    ${
                        (userRole == undefined || userRole == 'consumer') && consumerPices(selectFlItemCounter(data?.selectFl), data?.palanSelect, data?.singlePrices)
                    }
                    {
                        (userRole == 'retailer' || userRole == 'wholesale') && (selectFlItemCounter(data?.selectFl) * data?.singlePrices).toFixed(2)
                    }
                </span>
            </td>
            <td className="text-end"
                style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                    , gap: '5px'
                }}
            >
                <a
                    onClick={() => setRefundProduct({
                        type: true,
                        data: {data}
                    })}

                    className="btn btn-sm font-sm rounded btn-brand"
                > Refund </a>
                <a className="btn btn-sm font-sm rounded btn-brand"
                    onClick={() => setShrinkageProduct({
                        type: true,
                        data: {data}
                    })}
                > Shrinkage</a>
            </td>



        </tr>

    )
}

export default ViewRefundShrinkage