import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import demoImages from '../../../assets/imgs/items/1.jpg'
import { useDeleteBlogMutation } from '../../../app/services/blog'

function SingleBlog({ data, setOpen }) {
    const [deleteBlog, { isSuccess, isLoading }] = useDeleteBlogMutation()
    const BlogDelete = async (id) => {
        await deleteBlog(id)
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Blog Delete !')
        }
    }, [isSuccess])



    /*  discription not html tage show  */
    function extractTextFromHTML(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    }

    // Function to truncate text and add ellipsis
    function truncateText(text, maxLength) {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    }
    console.log(data)

    return (
        <tr >
            <td><img style={{ width: '60px', height: '60px', borderRadius: '100%' }} src={data?.images ? data?.images : demoImages} /></td>
            <td>{data?.title}</td>
            <td>{data?.categorie_id?.name}</td>
            <td>{data?.description && truncateText(extractTextFromHTML(data?.description), 10)}</td>
            <td className="text-end">
                <div className="text-end">
                    <a
                        onClick={() => setOpen({
                            type: true,
                            data: data
                        })}
                        className="btn btn-sm font-sm rounded btn-brand"> <i className="material-icons md-edit"></i> Edit </a>
                    <a style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} onClick={() => BlogDelete(data?._id)} className="btn btn-sm font-sm btn-light rounded"> <i className="material-icons md-delete_forever"></i> Delete </a>
                </div>
            </td>
        </tr>

    )
}

export default SingleBlog