import React, { useMemo } from 'react'


function Refund({ refundProduct, setRefundProduct }) {
    const { type, data } = refundProduct;
    let flaviorHandel = useMemo(() => data ? data?.data : [], [data])


    const mainFunctionHandel = (value) => {
        const findFlavior = data?.data?.selectFl?.filter(itemId => itemId?.id == value.id)
        if (value.type === '+') {
            console.log(findFlavior)


        } else if (value.type === '-') {
        }
    }


    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Product Refund</h2>
                                <button onClick={() => setRefundProduct({ type: false })}>X</button>
                            </div>
                        </div>
                        {/* ================  flavors  ================  */}
                        <div className="col-xl-8">
                            <div className="select_flavior_Item">
                                <span>
                                    {
                                        flaviorHandel?.selectFl?.map((flavior, i) => <span key={i} className="item_style">
                                            <span>{flavior?.name}</span>{` `}
                                            <span>{flavior?.item}</span>
                                            <div>
                                                <span className="item_handel"
                                                    onClick={() => mainFunctionHandel({
                                                        id: flavior?.id,
                                                        type: '+',
                                                    })}
                                                >+</span>
                                                <span className="item_handel"
                                                    onClick={() => mainFunctionHandel({
                                                        id: flavior?.id,
                                                        type: '-',
                                                    })}
                                                >-</span>
                                            </div>
                                            <span
                                                className="close-style">x</span>
                                        </span>)
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <a className="btn btn-sm font-sm rounded btn-brand mt-20"

                    >Refund Save</a>
                </section>

            </div>
        }
        </>
    )
}

export default Refund