import React, { useEffect } from 'react'
import { DepartmentSchema } from '../../../../helpers/validation/DepartmentSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateDepartmentMutation } from '../../../../app/services/department';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function DepartmentModalEdit({ modal, setOpen }) {
    const { type, data } = modal;
    const [deparmentUpdate, { isLoading, isSuccess, isError }] = useUpdateDepartmentMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(DepartmentSchema) });
    const onSubmit = async (value) => {
        const images = value?.images;
        const response = await handel_Images(images)
        if (response) {
            const updateValue = { id: data?._id, name: value?.department, images: response?.data?.secure_url}
            await deparmentUpdate(updateValue)
        } else {
            console.log(data?.images)
            const updateValue = { id: data?._id, name: value?.department, images: data?.images }
            await deparmentUpdate(updateValue)
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Department Edit !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry deparment not add!')
        }

    }, [isError, isLoading, isSuccess])
    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">

                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Department Edit</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label htmlFor="product_name" className="form-label">Department Name</label>
                                <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                    defaultValue={data?.name}
                                    {...register("department")}
                                />
                                {errors?.department && (
                                    <span className="form__error">{errors?.department.message}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Department Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                                {data?.images && <img style={{ width: '120px', height: '120px' }} src={data?.images} />
                                }
                                {errors?.images && (
                                    <span className="form__error">{errors?.images.message}</span>
                                )}
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

                        </form>
                    </div>
                </section>

            </div>
        }
        </>




    )
}

export default DepartmentModalEdit