import React, { useEffect } from 'react'
import { useAddDepartmentMutation } from '../../../../app/services/department';
import { DepartmentSchema } from '../../../../helpers/validation/DepartmentSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import handel_Images from '../../HandelImages';

function DepartmentModal({ modal, setOpen }) {
  const { type } = modal;
  const [AddDepartment, { isError, isLoading, isSuccess }] = useAddDepartmentMutation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(DepartmentSchema) });

  const onSubmit = async (data) => {
    try {
      const images = data?.images;
      const response = await handel_Images(images)
      if (response.data.secure_url) {
        await AddDepartment({ name: data?.department, images: response.data.secure_url });
      } else {
        await AddDepartment({ name: data?.department });
      }
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Department Add !')
      setOpen({ type: false })
      reset()
    }
    if (isError) {
      toast.error('sorry deparment not add!')
    }

  }, [isError, isLoading, isSuccess])

  return (
    <>{
      type && <div className='modal_product'>
        <section className="content-main">

          <div className="row">
            <div className="col-12">
              <div className="content-header">
                <h2 className="content-title">Department Add</h2>
                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label htmlFor="product_name" className="form-label">Department Name</label>
                <input type="text" placeholder="Type here" className="form-control" id="product_name"
                  {...register("department")}
                />
                {errors?.department && (
                  <span className="form__error">{errors?.department.message}</span>
                )}
              </div>
              <div className="mb-4">
                <label htmlFor="images" className="form-label">Department Images</label>
                <input type="file" placeholder="Type here" className="form-control" id="images"
                  {...register("images")}
                />
              </div>
              <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

            </form>
          </div>
        </section>

      </div>
    }
    </>
  )
}

export default DepartmentModal