import React, { useEffect } from 'react'
import { useAddSubCategoriesMutation } from '../../../../app/services/subCategories';
import { SubCategoriesSchema } from '../../../../helpers/validation/SubCategoriesSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function SubCategoriesModal({ modal, setOpen, data, categorieData, selectDepartment }) {
    const { type } = modal;
    const [AddSubCategories, { isError, isLoading, isSuccess }] = useAddSubCategoriesMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(SubCategoriesSchema) });
    const onSubmit = async (data) => {
        const images = data?.images;
        const response = await handel_Images(images)
        if (response) {
            await AddSubCategories({ ...data, images: response?.data?.secure_url })
        } else {
            await AddSubCategories(data)
        }

    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Sub Categories Add !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry Sub Categories not add!')
        }
    }, [isError, isLoading, isSuccess])

    return (
        type && <div className='modal_product'>
            <section className="content-main">

                <div className="row">
                    <div className="col-12">
                        <div className="content-header">
                            <h2 className="content-title">Sub Categorie Add</h2>
                            <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <label className="form-label">Department</label>
                            <select className="form-select"  {...register("development_id")} onChange={(e) => selectDepartment(e.target.value)}>
                                <option selected>Select Department</option>
                                {data?.development?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Category</label>
                            <select className="form-select" {...register("categorie_id")}>
                                <option selected>Select Category</option>
                                {categorieData?.map(data => <option value={data?._id} key={data?._id}>{data?.name}</option>)}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="product_name" className="form-label">Sub Categorie </label>
                            <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                {...register("name")}
                            />
                        </div>
                        {/* images handel  */}
                        <div className="mb-4">
                            <label htmlFor="images" className="form-label">Category Images</label>
                            <input type="file" placeholder="Type here" className="form-control" id="images"
                                {...register("images")}
                            />
                            {errors?.images && (
                                <span className="form__error">{errors?.images.message}</span>
                            )}
                        </div>
                        <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                    </form>
                </div>
            </section >

        </div >
    )
}

export default SubCategoriesModal