import React from "react";
import { BrandSchema } from "../../../helpers/validation/Brand";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import handel_Images from "../HandelImages";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useAddbrandMutation } from "../../../app/services/brand";

function CreateBrand({ modal, setOpen }) {
    const { type } = modal;
    const [AddBrand, { isError, isLoading, isSuccess }] = useAddbrandMutation()

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(BrandSchema) });

    const onSubmit = async (data) => {
        try {
            const images = data?.images;
            const response = await handel_Images(images)
            if (response) {
                await AddBrand({ title: data?.title, images: response.data.secure_url });
            } else {
                console.log('brand',data)
                await AddBrand({ title: data?.title });
            }
        } catch (error) {
            console.log("Error uploading image:", error);
        }
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success('Brand Add !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry Brand not add!')
        }

    }, [isError, isLoading, isSuccess])


    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">

                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Brand Add</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label htmlFor="product_name" className="form-label">Brnad Name</label>
                                <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                    {...register("title")}
                                />
                                {errors?.title && (
                                    <span className="form__error">{errors?.title.message}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Brand Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                        </form>
                    </div>
                </section>

            </div>
        }
        </>
    )
}
export default CreateBrand