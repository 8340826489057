import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import DashboardLayout from '../../components/layout/DashboardLayout';
import { useMemo } from 'react';
import GetSpinner from '../../helpers/shared/GetSpinner';
import { useGetSingleOrderSalesQuery } from '../../app/services/sales';
import ViewRefundShrinkage from './ViewRefundShrinkage';
import Refund from './Refund';
import Shrinkage from './Shrinkage';


function SalesRefundShrinkage() {
    const [refundProduct, setRefundProduct] = useState({
        type: false,
        data: null
    })
    const [shrinkageProduct, setShrinkageProduct] = useState({
        type: false,
        data: null
    })





    const { id } = useParams();
    const { data } = useGetSingleOrderSalesQuery(id)
    const ProductData = useMemo(() => data?.result ? data?.result : [], [data])




    return (
        <DashboardLayout>
            {
                ProductData ? <>
                    <section className="content-main">
                        <div className="content-header">
                            <div>
                                <h2 className="content-title card-title">Action Product</h2>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="40%">Product</th>
                                                        <th width="20%">Unit Price</th>
                                                        <th width="20%">Quantity</th>
                                                        <th width="20%">Total</th>
                                                        <th className="text-end">Action</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ProductData?.cartItems?.map(data => <ViewRefundShrinkage key={data?._id} data={data}
                                                            userRole={ProductData?.role}
                                                            setShrinkageProduct={setShrinkageProduct}
                                                            setRefundProduct={setRefundProduct}


                                                        />)

                                                    }
                                                    <tr className="text-end">
                                                        <td colSpan="6">
                                                            <article className="float-end">
                                                                <dl className="dlist">
                                                                    <dt>Total Quantity :</dt>
                                                                    <dd><b className="h5">{ProductData?.quantity}</b></dd>
                                                                </dl>
                                                                <dl className="dlist">
                                                                    <dt>Total Prices :</dt>
                                                                    <dd><b className="h5">{ProductData?.prices}</b></dd>
                                                                </dl>
                                                            </article>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>
                    <Refund refundProduct={refundProduct} setRefundProduct={setRefundProduct} />
                    <Shrinkage shrinkageProduct={shrinkageProduct} setShrinkageProduct={setShrinkageProduct} />
                </>
                    : <GetSpinner />
            }


        </DashboardLayout>
    )
}

export default SalesRefundShrinkage