import React, { useMemo } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAddSubCategoriesMutation, useGetSubCategoriesQuery } from '../../app/services/subCategories'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useGetCategoriesQuery, useQueryCategoriesMutation } from '../../app/services/categories'
import { SubCategoriesSchema } from '../../helpers/validation/SubCategoriesSchema'
import { useGetDepartmentQuery } from '../../app/services/department'
import SingleSubCategories from '../../components/elements/product/SingleSubCategories'
import { useState } from 'react'
import GetSpinner from '../../helpers/shared/GetSpinner'
import SubCategoriesModal from '../../components/elements/modal/product/SubCategoriesModal'
import SubCategoriesModalEdit from '../../components/elements/modal/product/SubCategoriesModalEdit'

export default function SubCategories() {
    const [Loading, setLoading] = useState(false)
    const { data: SubCategories, isLoading: LoadingSubCategories } = useGetSubCategoriesQuery()
    const { data } = useGetDepartmentQuery()
    const { data: categorieAllget } = useGetCategoriesQuery()
    const [QueryCategories, { data: categorieDatas }] = useQueryCategoriesMutation()
    const selectDepartment = async (id) => {
        await QueryCategories(id)
    }
    useEffect(() => {
        if (LoadingSubCategories) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [LoadingSubCategories])

    const categorieData = useMemo(() => (categorieDatas ? categorieDatas?.categorie : categorieAllget?.categorie), [
        categorieDatas,
        categorieAllget
    ]);




    /*     const handelgetagory =useF
     */









    /* ================  */
    /* Department modal section ==============  */
    const [subcategoriesCreateModal, setSubCategoriesCreateModal] = useState({ type: false, data: null })
    const [subCategoriesEditModal, setSubCategoriesEditModal] = useState({ type: false, data: null })

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Sub Categorie</h2>
                        <p>Add, edit or delete a Sub Categorie</p>
                    </div>
                    <div>
                        <a
                            onClick={() => setSubCategoriesCreateModal({ type: !subcategoriesCreateModal.type })}
                            className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Sub Create Categories</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Images</th>
                                                <th>Sub</th>
                                                <th>Categorie</th>
                                                <th>Department</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading && <GetSpinner />}
                                            {SubCategories?.subCategorie?.map(data => <SingleSubCategories key={data?._id} data={data}
                                                setOpen={setSubCategoriesEditModal}
                                            />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SubCategoriesModal
                modal={subcategoriesCreateModal}
                setOpen={setSubCategoriesCreateModal}
                data={data}
                categorieData={categorieData}
                selectDepartment={selectDepartment}
            />
            <SubCategoriesModalEdit
                modal={subCategoriesEditModal}
                setOpen={setSubCategoriesEditModal}
                data={data}
                categorieData={categorieData}
                selectDepartment={selectDepartment}
            />


        </DashboardLayout>
    )
}
