import React, { useState } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useGetCategoriesQuery } from '../../app/services/categories'
import { useEffect } from 'react'
import SingleCategory from '../../components/elements/product/SingleCategory'
import GetSpinner from '../../helpers/shared/GetSpinner'
import CaregorieModal from '../../components/elements/modal/product/CaregorieModal'
import CaregorieModalEdit from '../../components/elements/modal/product/CaregorieModalEdit'
import { useGetDepartmentQuery } from '../../app/services/department'
function Categories() {
    const [Loading, setLoading] = useState(false)
    const { data } = useGetDepartmentQuery()
    const { data: CategoriesData, isLoading: CategoriesLoging } = useGetCategoriesQuery()
    useEffect(() => {
        if (CategoriesLoging) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [CategoriesLoging])

    /* Department modal section ==============  */
    const [categoriesCreateModal, setCategoriesCreateModal] = useState({ type: false, data: null })
    const [CategoriesEditModal, setCategoriesEditModal] = useState({ type: false, data: null })

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Categories</h2>
                        <p>Add, edit or delete a Category</p>
                    </div>
                    <div>
                        <a
                            onClick={() => setCategoriesCreateModal({ type: !categoriesCreateModal.type })}
                            className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Create Categories</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>ID</th>
                                                <th>Category</th>
                                                <th>Development</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading && <GetSpinner />}
                                            {CategoriesData?.categorie?.map(data => <SingleCategory key={data?._id} data={data}
                                                setOpen={setCategoriesEditModal}
                                            />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CaregorieModal
                modal={categoriesCreateModal}
                setOpen={setCategoriesCreateModal}
                data={data}
            />
            <CaregorieModalEdit
                modal={CategoriesEditModal}
                setOpen={setCategoriesEditModal}
                data={data}
            />

        </DashboardLayout>
    )
}

export default Categories