import React, { useEffect } from 'react'
import { useUpdateChildSubCategoriesMutation } from '../../../../app/services/childsubCategories';
import { ChildSubCategorySchema } from '../../../../helpers/validation/ChildSubCategorySchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function ChildSubCategoriesEdit({ modal, setOpen, department, categorie, subCategorie, selectDepartment, selectCategory }) {
    const { type, data } = modal;
    const [UpdateChildSubCategories, { isError, isLoading, isSuccess }] = useUpdateChildSubCategoriesMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(ChildSubCategorySchema) });

    const onSubmit = async (value) => {
        const images = value?.images;
        const response = await handel_Images(images)
        if (response) {
            const UpdateData = { ...value, id: data?._id, images: response?.data?.secure_url };
            await UpdateChildSubCategories(UpdateData)
        } else {
            const UpdateData = { ...value, id: data?._id, images: data?.images };
            await UpdateChildSubCategories(UpdateData)
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Child Sub Caregorie Edit !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('Sorry Child Sub Caregorie not add!')
        }

    }, [isError, isLoading, isSuccess])

    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Child Sub Categorie Add</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label className="form-label">Department</label>
                                <select className="form-select"  {...register("development_id")} onChange={(e) => selectDepartment(e.target.value)}>
                                    {department?.development?.map(department => <option value={department?._id} key={department?._id}
                                        selected={department?._id == data?.development_id?._id}
                                    >{department?.name} </option>)}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Category</label>
                                <select className="form-select" {...register("categorie_id")} onChange={(e) => selectCategory(e.target.value)}>
                                    {categorie?.map(category => <option value={category?._id} key={category?._id}
                                        selected={category?._id == data?.categorie_id?._id}
                                    >{category?.name}</option>)}
                                </select>
                            </div>
                            <div className="mb-4">
                                <select className="form-select" {...register("sub_id")} >
                                    <label className="form-label">Sub Categorie</label>
                                    {subCategorie?.map(subCategorie => <option value={subCategorie?._id} key={subCategorie?._id}
                                        selected={subCategorie?._id == data?.sub_id?._id}
                                    >{subCategorie?.name}</option>)}
                                </select>

                            </div>
                            <div className="mb-4">
                                <label htmlFor="product_name" className="form-label">Child Sub Categorie</label>
                                <input type="text" placeholder="Type here" className="form-control" id="product_name" {...register("name")}
                                    defaultValue={data?.name}
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Department Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                                {data?.images && <img style={{ width: '120px', height: '120px' }} src={data?.images} />
                                }
                                {errors?.images && (
                                    <span className="form__error">{errors?.images.message}</span>
                                )}
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

                        </form>
                    </div>
                </section >

            </div >
        }
        </>
    )
}

export default ChildSubCategoriesEdit