import React, { useEffect, useMemo, useState } from 'react'
import { useGetPopularProductQuery, useUpdateProductTypeMutation } from '../../../app/services/product'
import DashboardLayout from '../../../components/layout/DashboardLayout'
import GetSpinner from '../../../helpers/shared/GetSpinner'
import SingleProdutType from '../../../components/elements/product/SingleProdutType'
import { toast } from 'react-toastify'
function Popular() {
    const [deleteContent, { isLoading: typedeleLogin, isSuccess: deleteType }] = useUpdateProductTypeMutation()
    const [Loading, setLoading] = useState(false)
    const { data, isLoading, isSuccess } = useGetPopularProductQuery()
    useEffect(() => {
        if (deleteType) {
            toast.success('Success Delete!')
        }
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])
    const ProductData = useMemo(() => (data ? data : []), [
        data,
    ]);
    const ProductTypeDelete = async (id) => {
        const typeInfo = { _id: id, product_type_popular: false }
        //await handelClick(id, deleteContent(typeInfo))
        await deleteContent(typeInfo)
        toast.success('Success Delete!')
    }
    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Products Type Popular </h2>
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                <select className="form-select">
                                    <option selected>All category</option>
                                    <option>Electronics</option>
                                    <option>Clothes</option>
                                    <option>Automobile</option>
                                </select>
                            </div>
                            <div className="col-md-2 col-6">
                                <input type="date" defaultValue="02.05.2021" className="form-control" />
                            </div>
                            <div className="col-md-2 col-6">
                                <select className="form-select">
                                    <option selected>Status</option>
                                    <option>Active</option>
                                    <option>Disabled</option>
                                    <option>Show all</option>
                                </select>
                            </div>
                        </div>
                    </header>
                    <div className="card-body">
                        {Loading && <GetSpinner />}
                        {
                            ProductData?.map((data) => <SingleProdutType data={data} key={data?._id}
                                isLoading={typedeleLogin}
                                ProductTypeDelete={ProductTypeDelete}
                            />)
                        }
                    </div>
                </div>
            </section>



        </DashboardLayout>
    )
}
export default Popular