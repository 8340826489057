import * as yup from "yup";
export const ProductsSchema = yup.object().shape({
    product_name: yup.string()
        .required("Prouct name field can not be empty.")
        .min(2, " must contain at least 6 characters."),
    upc: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    slug: yup.string(),
    sku: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    upcBox: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    development_id: yup.string(),
    categorie_id: yup.string(),
    sub_id: yup.string(),
    childSub_id: yup.string(),
    brand_id: yup.string(),
    status: yup.boolean(),
    quantity: yup.number(),
    cost: yup.string(),
    pcs: yup.number(),

    /* Offline  prices */
    saleing_Price: yup.number().required("Saleing_Price empty."),
    xs: yup.string(),
    sm: yup.string(),
    md: yup.string(),
    lg: yup.string(),
    xl: yup.string(),
    xl_2: yup.string(),
    xl_3: yup.string(),
    xl_4: yup.string(),
    xl_5: yup.string(),
    xl_6: yup.string(),
    xl_7: yup.string(),
    /* Retailer prices */
    retailer_saleing_Price: yup.number().required("Saleing_Price empty."),
    retailer_xs: yup.string(),
    retailer_sm: yup.string(),
    retailer_md: yup.string(),
    retailer_lg: yup.string(),
    retailer_xl: yup.string(),
    retailer_xl_2: yup.string(),
    retailer_xl_3: yup.string(),
    retailer_xl_4: yup.string(),
    retailer_xl_5: yup.string(),
    retailer_xl_6: yup.string(),
    retailer_xl_7: yup.string(),
    /* Wholesaler prices */
    wholesaler_saleing_Price: yup.number().required("Saleing_Price empty."),
    wholesaler_xs: yup.string(),
    wholesaler_sm: yup.string(),
    wholesaler_md: yup.string(),
    wholesaler_lg: yup.string(),
    wholesaler_xl: yup.string(),
    wholesaler_xl_2: yup.string(),
    wholesaler_xl_3: yup.string(),
    wholesaler_xl_4: yup.string(),
    wholesaler_xl_5: yup.string(),
    wholesaler_xl_6: yup.string(),
    wholesaler_xl_7: yup.string(),
    /* Consumer prices */
    consumer_saleing_Price: yup.number().required("Saleing_Price empty."),
    consumer_xs: yup.string(),
    consumer_sm: yup.string(),
    consumer_md: yup.string(),
    consumer_lg: yup.string(),
    consumer_xl: yup.string(),
    consumer_xl_2: yup.string(),
    consumer_xl_3: yup.string(),
    consumer_xl_4: yup.string(),
    consumer_xl_5: yup.string(),
    consumer_xl_6: yup.string(),
    consumer_xl_7: yup.string(),
    /* meta tage seo  */
    tag: yup.string(),
    meta_title: yup.string(),
    meta_description: yup.string(),
    /* Deals pcs */
    retailer_deals_title: yup.string(),
    retailer_deals_status: yup.boolean(),
    /* =========== retailer Deals pcs =========== */
    retailer_sales_offer_status: yup.boolean(),
    retailer_sales_offer_type: yup.string(),
    retailer_sales_offer_amount: yup.string(),
    retailer_sales_offer_totatPrices: yup.string(),
    retailer_sales_offer_color: yup.string(),
    retailer_sales_offer_content: yup.string(),
    /* retailer Deals pcs */
    wholesaler_deals_title: yup.string(),
    wholesaler_deals_status: yup.boolean(),
    /* retailer sale offer   */
    wholesaler_sales_offer_status: yup.boolean(),
    wholesaler_sales_offer_type: yup.string(),
    wholesaler_sales_offer_amount: yup.string(),
    wholesaler_sales_offer_totatPrices: yup.string(),
    wholesaler_sales_offer_color: yup.string(),
    wholesaler_sales_offer_content: yup.string(),

    /* retailer Deals pcs */
    consumer_deals_title: yup.string(),
    consumer_deals_status: yup.boolean(),
    /* retailer sale offer   */
    consumer_sales_offer_status: yup.boolean(),
    consumer_sales_offer_type: yup.string(),
    consumer_sales_offer_amount: yup.string(),
    consumer_sales_offer_totatPrices: yup.string(),
    consumer_sales_offer_color: yup.string(),
    consumer_sales_offer_content: yup.string(),







    /* coupon offer   */
    coupon_offer_type: yup.string(),
    coupon_offer: yup.boolean(),
    coupon_Offer_amount: yup.string(),
    /* coupon offer   */
    promo_offer_type: yup.string(),
    promo_code: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    promo_amount: yup.string(),
    /* coupon offer   */
    deal_offer_type: yup.string(),
    deal_time_type: yup.string(),
    deal_amount: yup.string(),
})
export const UpdateProductsSchema = yup.object().shape({
    product_name: yup.string(),
    slug: yup.string(),
    upc: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    sku: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    upcBox: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    development_id: yup.string(),
    categorie_id: yup.string(),
    sub_id: yup.string(),
    childSub_id: yup.string(),
    brand_id: yup.string(),
    status: yup.boolean(),
    quantity: yup.number(),
    pcs: yup.number(),
    cost: yup.string(),
    /* ofline prices  */
    saleing_Price: yup.number(),
    xs: yup.string(),
    sm: yup.string(),
    md: yup.string(),
    lg: yup.string(),
    xl: yup.string(),
    xl_2: yup.string(),
    xl_3: yup.string(),
    xl_4: yup.string(),
    xl_5: yup.string(),
    xl_6: yup.string(),
    xl_7: yup.string(),

    retailer_saleing_Price: yup.number().required("Saleing_Price empty."),
    retailer_xs: yup.string(),
    retailer_sm: yup.string(),
    retailer_md: yup.string(),
    retailer_lg: yup.string(),
    retailer_xl: yup.string(),
    retailer_xl_2: yup.string(),
    retailer_xl_3: yup.string(),
    retailer_xl_4: yup.string(),
    retailer_xl_5: yup.string(),
    retailer_xl_6: yup.string(),
    retailer_xl_7: yup.string(),
    /* Wholesaler prices */
    wholesaler_saleing_Price: yup.number().required("Saleing_Price empty."),
    wholesaler_xs: yup.string(),
    wholesaler_sm: yup.string(),
    wholesaler_md: yup.string(),
    wholesaler_lg: yup.string(),
    wholesaler_xl: yup.string(),
    wholesaler_xl_2: yup.string(),
    wholesaler_xl_3: yup.string(),
    wholesaler_xl_4: yup.string(),
    wholesaler_xl_5: yup.string(),
    wholesaler_xl_6: yup.string(),
    wholesaler_xl_7: yup.string(),
    /* consume prices */
    consumer_saleing_Price: yup.number().required("Saleing_Price empty."),
    consumer_xs: yup.string(),
    consumer_sm: yup.string(),
    consumer_md: yup.string(),
    consumer_lg: yup.string(),
    consumer_xl: yup.string(),
    consumer_xl_2: yup.string(),
    consumer_xl_3: yup.string(),
    consumer_xl_4: yup.string(),
    consumer_xl_5: yup.string(),
    consumer_xl_6: yup.string(),
    consumer_xl_7: yup.string(),
    /* meta tage seo  */
    tag: yup.string(),
    meta_title: yup.string(),
    meta_description: yup.string(),

    /* retailer Deals pcs */
    retailer_deals_title: yup.string(),
    retailer_deals_status: yup.boolean(),
    /* retailer sale offer   */
    retailer_sales_offer_status: yup.boolean(),
    retailer_sales_offer_type: yup.string(),
    retailer_sales_offer_amount: yup.string(),
    retailer_sales_offer_totatPrices: yup.string(),
    retailer_sales_offer_color: yup.string(),
    retailer_sales_offer_content: yup.string(),

    /* retailer Deals pcs */
    wholesaler_deals_title: yup.string(),
    wholesaler_deals_status: yup.boolean(),
    /* retailer sale offer   */
    wholesaler_sales_offer_status: yup.boolean(),
    wholesaler_sales_offer_type: yup.string(),
    wholesaler_sales_offer_amount: yup.string(),
    wholesaler_sales_offer_totatPrices: yup.string(),
    wholesaler_sales_offer_color: yup.string(),
    wholesaler_sales_offer_content: yup.string(),

    /* retailer Deals pcs */
    consumer_deals_title: yup.string(),
    consumer_deals_status: yup.boolean(),
    /* retailer sale offer   */
    consumer_sales_offer_status: yup.boolean(),
    consumer_sales_offer_type: yup.string(),
    consumer_sales_offer_amount: yup.string(),
    consumer_sales_offer_totatPrices: yup.string(),
    consumer_sales_offer_color: yup.string(),
    consumer_sales_offer_content: yup.string(),

    /* coupon offer   */
    coupon_offer_type: yup.string(),
    coupon_offer: yup.boolean(),
    coupon_Offer_amount: yup.string(),
    /* coupon offer   */
    promo_offer_type: yup.string(),
    promo_code: yup.string().matches(/^\S*$/, 'No spaces allowed'),
    promo_amount: yup.string(),
    /* coupon offer   */
    deal_offer_type: yup.string(),
    deal_time_type: yup.string(),
    deal_amount: yup.string(),
    new_quantity: yup.string(),
    quantity_action: yup.string(),
})
export const ProductExtraCostSchema = yup.object().shape({
    cost: yup.string().required("Prouct name field can not be empty."),
})
