import React, { useEffect, useMemo, useState } from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import SingleBrandItem from '../components/elements/brand/SingleBrandItem'
import CreateBrand from '../components/elements/brand/CreateBrand'
import BrandEdit from '../components/elements/brand/BrandEdit'
import GetSpinner from '../helpers/shared/GetSpinner'
import { useGetbrandQuery } from '../app/services/brand'

function Brands() {
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [brandCreateModal, setbrandCreateModal] = useState({ type: false, data: null })
    const [brandEditModal, setbrandEditModal] = useState({ type: false, data: null })
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 20, });
    const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}`;
    const { data, isLoading } = useGetbrandQuery(pathname)

    /* */
    console.log('data', data)


    const BrandData = useMemo(() => (data ? data?.brand : []), [
        data,
        search
    ]);
    console.log('brand check ', BrandData)
    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        }
        if (data) {
            setLoading(false)
        }

    }, [isLoading, data])


    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Brand</h2>
                        <p>Brand and vendor management</p>
                    </div>
                    <div>
                        <a onClick={() => setbrandCreateModal({ type: !brandCreateModal.type })} className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Add New Brand</a>
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row gx-3">
                            <div className="col-lg-4 mb-lg-0 mb-15 me-auto">
                                <input type="text" placeholder="Search..." className="form-control" />
                            </div>
                            <div className="col-lg-2 col-6">
                                <input type="date" className="form-control" name="" />
                            </div>
                        </div>
                    </header>
                    {/* 
                    
                    */}


                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Images</th>
                                                    <th>Title</th>
                                                    <th>Item</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Loading && <GetSpinner />}
                                                {BrandData?.map(data => <SingleBrandItem data={data} key={data._id}
                                                    setOpen={setbrandEditModal}
                                                />
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* create brand */}
            <CreateBrand
                modal={brandCreateModal}
                setOpen={setbrandCreateModal}
            />




            {/* edit  brand */}
            <BrandEdit
                modal={brandEditModal}
                setOpen={setbrandEditModal}

            />



        </DashboardLayout>
    )
}

export default Brands