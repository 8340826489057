import React, { useEffect, useState } from 'react'
import { useDeleteOnlineOederMutation, usePatchOnlineOederMutation } from '../../../app/services/onlineOrder';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

function SingleSaleView({ singleOnlineOrder }) {
    const navigate = useNavigate();

    const updatedDate = singleOnlineOrder?.createdAt;
    const updatedMoment = moment(updatedDate);
    const formattedDate = updatedMoment.format("DD MMMM, YYYY");
    /* rtk hook use */
    const [OrdserstatusChanges, { isLoading, isError, error }] = usePatchOnlineOederMutation()
    const [OrdserDelete, { isLoading: deleteLoading }] = useDeleteOnlineOederMutation()
    const orderStatusHandel = async (id, value) => {
        await OrdserstatusChanges({ id: id, data: { status: value } })
    }
    const orderDeleteHandel = async (id) => {
        await OrdserDelete(id)
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message)
        }
    }, [isError, error])





    return (
        <>
            <tr>
                <td>{singleOnlineOrder?.orderId}</td>
                <td><b>{singleOnlineOrder?.user_id?.name}</b></td>
                <td>{singleOnlineOrder?.user_id?.email}</td>
                <td>${singleOnlineOrder?.prices?.toFixed(2)}</td>
                <td>
                    <div className="btn-group">
                        <span className={`badge rounded-pill ${singleOnlineOrder?.status === 'approved' ? 'alert-warning' : singleOnlineOrder?.status === 'shipped' ? 'alert-primary' : singleOnlineOrder?.status === 'delivered' ? 'alert-success' : 'alert-danger'}`}>{singleOnlineOrder?.status}</span>
                        <a
                            style={{ fontSize: "20px" }}
                            className="dropdown-toggle px-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" size='20px'></a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => orderStatusHandel(singleOnlineOrder?._id, 'approved')}>approved</a>
                            <a className="dropdown-item" onClick={() => orderStatusHandel(singleOnlineOrder?._id, 'shipped')}>shipped</a>
                            <a className="dropdown-item" onClick={() => orderStatusHandel(singleOnlineOrder?._id, 'delivered')}>delivered</a>
                        </div>
                    </div>
                </td>
                <td>{formattedDate}</td>
                <td className="text-end">
                    <div className="btn-group">
                        <a href="#" data-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm"
                            aria-haspopup="true" aria-expanded="false"
                        > <i className="material-icons md-more_horiz"></i> </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item"
                                onClick={() => navigate(`/sale/detail/${singleOnlineOrder?._id}`)}
                            >View detail</a>
                            <a className="dropdown-item"
                                onClick={() => navigate(`/sale/action/${singleOnlineOrder?._id}`)}
                            >Action</a>
                            {singleOnlineOrder?.status === 'pending' && <a onClick={() => orderDeleteHandel(singleOnlineOrder?._id)} className="dropdown-item" >Delete</a>}
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default SingleSaleView