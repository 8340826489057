import React, { useRef } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useParams } from 'react-router-dom'
import GetSpinner from '../../helpers/shared/GetSpinner'
import { useMemo } from 'react'
import moment from 'moment/moment';
import zavaLogo from '../../assets/imgs/theme/logo.png'
import { consumerPices, totalItemOrder, totalPrices } from '../../helpers/calculator/invoice/OrderDetailsCalculator'
import { useGetSingleOrderSalesQuery } from '../../app/services/sales'
function SalesDetail() {
    const { id } = useParams()
    const { data } = useGetSingleOrderSalesQuery(id)
    const invoiceData = useMemo(() => data?.result ? data?.result : [], [data])
    const ref = React.createRef();
    const printAreaRef = useRef(null);
    const handlePrint = () => {
        const printContent = printAreaRef.current.innerHTML;
        const printWindow = window.open(id, '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`
        .order_container {
            max-width: 800px !important;
            margin: 0 auto !important;
            padding: 30px;
            border: 1px solid;
          
          }
          .img_handel{
            max-width: 220px;
          }
          
          .order_topContect {
            display: flex;
            justify-content: space-between;
            gap: 14px;
          }
          
          span {
            font-weight: normal;
            font-size: 15px;
          }
          
          strong span {
            margin-left: 20px;
          }
          
          .invoic_contect {
            margin: 5px 0px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: 14px;
          }
          .invoic_contect .invoice_bold {
            font-weight: 900;
            color: black;
          }
          
          /* table */
          #customers {
            font-family: Arial, Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
          }
          
          #customers td,
          #customers th {
            border: 1px solid #ddd;
            padding: 8px;
          }
          
          #customers tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          
          #customers tr:hover {
            background-color: #ddd;
          }
          
          #customers th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            color: black;
          }
          
          
          
          .invoicPayment {
            margin-top: 10px;
          }
          
          .customersHisabContct {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: start;
            gap: 5px;
          }
          
          
          .customersHisab {
            max-width: 100% !important;
          
          }
          
          .customersHisab div {
            display: flex;
            justify-content: space-between;
            gap: 10px;
          }
          .paymentTypeStyles div{
            display: flex;
            gap: 10px;
          }
          
          .cashCheck {
            width: fit-content;
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 5px;
          }
          
          .cashCheck div {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 5px;
          }
          .totalQuantity{
            text-align: end;
          }
    
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write(printContent);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();

    };
    const updatedDate = invoiceData?.updatedAt;
    const updatedMoment = moment(updatedDate);
    const formattedDate = updatedMoment.format("DD MMMM, YYYY");
    return (
        <DashboardLayout>
            {!data && <GetSpinner />}
            <>
                <button onClick={handlePrint}> <i className="material-icons md-print"></i></button>

                <div ref={printAreaRef} className='order_container'>
                    <div className='order_topContect'>
                        <div>
                            <strong>SOLD BY</strong>
                            <div>
                                <strong>ZAVA WHOLESALE</strong><br />
                                <span>3926 Coral Ridge Dr. <br /> Coral Springs FL 33065
                                </span><br />
                                <span>USA</span><br />
                            </div>
                            <span>Office : 1-754-302-8292</span><br />
                        </div>
                        <div>
                            <strong>SOLD TO</strong>
                            <div>
                                <span>{invoiceData?.shipping_name}</span><br />
                                <span>{invoiceData?.shipping_company_name}</span><br />
                                <span>{invoiceData?.shipping_address}</span><br />
                                <span>{invoiceData?.shipping_city}, {invoiceData?.shipping_state}, {invoiceData?.shipping_zip_code}</span><br />
                                <span>{invoiceData?.shipping_number}</span><br />
                            </div>
                        </div>
                        <div>
                            <img className='img_handel' src={zavaLogo} />
                            <p><strong>Order by </strong> retwho.com</p>
                        </div>
                    </div>
                    <div className="invoic_contect">
                        <div>
                            <span className='invoice_bold'><strong>Invoice</strong> # {invoiceData?.orderId}</span> <br />
                        </div>
                        <div>
                            <strong>Issue date</strong> <br />
                            <span>{formattedDate}</span>
                        </div>
                        <div>
                            <strong>Due date</strong><br />
                            <span>{formattedDate}</span>
                        </div>
                    </div>
                    <div>
                        <table id="customers">
                            <tr>
                                <th>Product Name</th>
                                <th>Attributes</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Amount</th>
                            </tr>
                            {
                                invoiceData?.cartItems?.map(data => <tr key={data?._id}>
                                    <td>
                                        {
                                            data?.product_name
                                        }
                                    </td>
                                    <td>
                                        {
                                            data?.selectFl?.map(selectflavor => <sapn >{selectflavor?.name} ({selectflavor?.item})</sapn>)
                                        }
                                    </td>
                                    <td>
                                        {
                                            totalItemOrder(data)
                                        }
                                    </td>
                                    <td>{data?.singlePrices}</td>
                                    <td>{consumerPices(totalItemOrder(data), data?.palanSelect, data?.singlePrices)}</td>
                                </tr>)
                            }
                            <tr>
                                <td ></td>
                                <td style={{ textAlign: 'end' }}
                                    className='totalQuantity'
                                >Total Qty</td>
                                <td >{invoiceData?.quantity}</td>
                                <td style={{ textAlign: 'end' }}>Total</td>
                                <td>{invoiceData?.prices?.toFixed(2)}</td>
                            </tr>
                        </table>
                    </div>
                    <div className='invoicPayment'>
                        <p style={{ textTransform: 'uppercase' }}>PAID BY # {invoiceData?.paymentmethods}</p>
                        <div className="customersHisabContct">

                            <div>
                                <div className="customersHisab">
                                    <div>
                                        <strong>{invoiceData?.status === 'delivered' ? 'Paid' : 'Past Due'}</strong>
                                        <span>{invoiceData?.prices?.toFixed(2)}</span>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="customersHisab">
                                    <div>
                                        <strong>Invoice total</strong>
                                        <span>{invoiceData?.prices?.toFixed(2)}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </>

        </DashboardLayout>
    )
}

export default SalesDetail

