import { useEffect } from 'react'
import React from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import { useGetDepartmentQuery } from '../../app/services/department'
import SingleDepartment from '../../components/elements/product/SingleDepartment'
import { useState } from 'react'
import GetSpinner from '../../helpers/shared/GetSpinner'
import DepartmentModal from '../../components/elements/modal/product/DepartmentModal'
import DepartmentModalEdit from '../../components/elements/modal/product/DepartmentModalEdit'
function Department() {
    const [Loading, setLoading] = useState(false)
    const { data, isLoading: deparmentLoging } = useGetDepartmentQuery()
    useEffect(() => {
        if (deparmentLoging) {
            setLoading(true)
        }
        if (data) {
            setLoading(false)
        }

    }, [deparmentLoging, data])

    /* Department modal section ==============  */
    const [departmentCreateModal, setDepartmentCreateModal] = useState({ type: false, data: null })
    const [departmentEditModal, setDepartmentEditModal] = useState({ type: false, data: null })

    return (
        <DashboardLayout>
            <section className="content-main">
                <div className=" content-header">
                    <div>
                        <h2 className="content-title card-title">Department</h2>
                        <p>Add, edit or delete a Department</p>
                    </div>
                    <div>
                        <a
                            onClick={() => setDepartmentCreateModal({ type: !departmentCreateModal.type })}
                            className="btn btn-primary"><i className="text-muted material-icons md-post_add"></i>Create Department</a>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Images</th>
                                                <th>ID</th>
                                                <th>Department</th>
                                                <th>Email</th>
                                                <th className="text-end">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Loading && <GetSpinner />}
                                            {data?.development?.map(data => <SingleDepartment data={data} key={data.key}
                                                setOpen={setDepartmentEditModal}
                                            />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* create  */}
            <DepartmentModal
                modal={departmentCreateModal}
                setOpen={setDepartmentCreateModal}
            />
            <DepartmentModalEdit
                modal={departmentEditModal}
                setOpen={setDepartmentEditModal}
            />
            {/* edit   */}



        </DashboardLayout>
    )
}
export default Department