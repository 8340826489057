import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { ProductExtraCostSchema } from '../../../../helpers/validation/ProductsSchema';
import { useUpdateProductTypeMutation, useGetSingleproductQuery } from '../../../../app/services/product';

function ExtraCostAddModel({ extraOpen, setExtraOpen, productData }) {
    const [cursorHandel, setCursorHandel] = useState(false)
    const { _id } = productData;
    const { data } = useGetSingleproductQuery(productData?._id)
    const [ProductAllTypeHandel, { isLoading, isSuccess, isError }] = useUpdateProductTypeMutation()

    const SingleProductData = useMemo(() => (data ? data?.product : []), [
        data,
    ]);
    /*  =====================  product type handel =====================  */
    const product_type_handel_popular = async (id) => {
        const typeInfo = { _id: id, product_type_popular: !SingleProductData?.product_type_popular }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_handel_topSelling = async (id) => {
        const typeInfo = { _id: id, product_type_topSelling: !SingleProductData?.product_type_topSelling }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_handel_trending = async (id) => {
        const typeInfo = { _id: id, product_type_trending: !SingleProductData?.product_type_trending }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_handel_deals_of_day = async (id) => {
        const typeInfo = { _id: id, product_type_deals_of_day: !SingleProductData?.product_type_deals_of_day }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_handel_featured = async (id) => {
        const typeInfo = { _id: id, product_type_featured: !SingleProductData?.product_type_featured }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_top_rated = async (id) => {
        const typeInfo = { _id: id, product_type_rated: !SingleProductData?.product_type_rated }
        await ProductAllTypeHandel(typeInfo)
    }
    const product_type_handel_dailybestsells = async (id) => {
        const typeInfo = { _id: id, product_type_dailybestsells: !SingleProductData?.product_type_dailybestsells }
        await ProductAllTypeHandel(typeInfo)
    }

    useEffect(() => {
        if (isSuccess) {
            toast.success('Success Update!')
            setCursorHandel(false)

        }
        if (isError) {
            toast.error('Sorry Not Update!')
            setCursorHandel(false)
        }


    }, [isError, isLoading, isSuccess])
    return (
        <>
            {
                extraOpen && <div className='modal_product'>
                    <section style={{ cursor: isLoading ? ' not-allowed' : '' }} className="content-main">
                        <div className="row">
                            <div className="col-12">
                                <div className="content-header">
                                    <h2 className="content-title">Product Type</h2>
                                    <button onClick={() => setExtraOpen(!extraOpen)}>X</button>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="populer">Popular :</label>
                                    <input
                                        onClick={() => product_type_handel_popular(_id)}
                                        type="checkbox" id="populer" checked={SingleProductData?.product_type_popular} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="top_selling">Top Selling :</label>
                                    <input
                                        onClick={() => product_type_handel_topSelling(_id)}
                                        type="checkbox" id="top_selling" checked={SingleProductData?.product_type_topSelling} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="trending_products">Trending Products :</label>
                                    <input
                                        onClick={() => product_type_handel_trending(_id)}
                                        type="checkbox" id="trending_products" checked={SingleProductData?.product_type_trending} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="deals_of_day">Deals Of The Day :</label>
                                    <input
                                        onClick={() => product_type_handel_deals_of_day(_id)}
                                        type="checkbox" id="deals_of_day" checked={SingleProductData?.product_type_deals_of_day} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="featured">Featured :</label>
                                    <input
                                        onClick={() => product_type_handel_featured(_id)}
                                        type="checkbox" id="featured" name="vehicle1" value="Bike" checked={SingleProductData?.product_type_featured} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="featured">Top Rated :</label>
                                    <input
                                        onClick={() => product_type_top_rated(_id)}
                                        type="checkbox" id="featured" name="vehicle1" value="Bike" checked={SingleProductData?.product_type_rated} />
                                </div>
                                <div className="mb-4 product_type_handel">
                                    <label htmlFor="featured">Deals Of The Day :</label>
                                    <input
                                        onClick={() => product_type_handel_dailybestsells(_id)}
                                        type="checkbox" id="featured" name="vehicle1" value="Bike" checked={SingleProductData?.product_type_dailybestsells} />
                                </div>
                            </div>

                        </div>
                    </section>

                </div>
            }
        </>
    )
}
export default ExtraCostAddModel