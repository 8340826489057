import React, { useEffect } from 'react'
import { SubCategoriesSchema } from '../../../../helpers/validation/SubCategoriesSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useUpdateSubCategoriesMutation } from '../../../../app/services/subCategories';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function SubCategoriesModalEdit({ modal, setOpen, data: departmentData, selectDepartment, categorieData }) {
    const { type, data } = modal;
    const [UpdateSubCategories, { isError, isSuccess, isLoading }] = useUpdateSubCategoriesMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(SubCategoriesSchema) });
    const onSubmit = async (value) => {
        const images = value?.images;
        const response = await handel_Images(images)
        console.log(response)
        if (response) {
            const UpdateData = { ...value, id: data?._id, images: response?.data?.secure_url };
            await UpdateSubCategories(UpdateData)
        } else {
            const UpdateData = { ...value, id: data?._id, images: data?.images };
            await UpdateSubCategories(UpdateData)
        }

    }

    useEffect(() => {
        if (isSuccess) {
            toast.success('Sub Caregorie Edit !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry Sub Caregorie not add!')
        }

    }, [isError, isLoading, isSuccess])

    return (
        type && <div className='modal_product'>
            <section className="content-main">

                <div className="row">
                    <div className="col-12">
                        <div className="content-header">
                            <h2 className="content-title">Sub Categorie Edit</h2>
                            <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <label className="form-label">Department</label>
                            <select className="form-select"  {...register("development_id")} onChange={(e) => selectDepartment(e.target.value)}>
                                {departmentData?.development?.map(development => <option value={development?._id} key={development?._id}
                                    selected={development?._id == data?.development_id?._id}
                                >{development?.name}</option>)}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Category</label>
                            <select className="form-select" {...register("categorie_id")}>
                                {categorieData?.map(category => <option value={category?._id} key={category?._id}
                                    selected={category?._id == data?.categorie_id?._id}
                                >{category?.name}</option>)}
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="product_name" className="form-label">Sub Categorie </label>
                            <input type="text" placeholder="Type here" className="form-control" id="product_name"
                                defaultValue={data?.name}
                                {...register("name")}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="images" className="form-label">Department Images</label>
                            <input type="file" placeholder="Type here" className="form-control" id="images"
                                {...register("images")}
                            />
                            {data?.images && <img style={{ width: '120px', height: '120px' }} src={data?.images} />
                            }
                            {errors?.images && (
                                <span className="form__error">{errors?.images.message}</span>
                            )}
                        </div>
                        <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                    </form>
                </div>
            </section >

        </div >
    )
}

export default SubCategoriesModalEdit