import React from 'react'
import JoditEditor from "jodit-react";
import { useRef } from 'react';
import { BlogSchema } from '../../../../helpers/validation/Blog';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';
import { useUpdateBlogMutation } from '../../../../app/services/blog';

function BlogModalEdit({ modal, setOpen, categories }) {
  const editor = useRef(null);
  const { type, data } = modal;
  const [productSlug, setProductSlug] = useState(data?.slug);

  const [updateBlog, { isError, isLoading, isSuccess }] = useUpdateBlogMutation()

  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(BlogSchema) });

  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '');
  };
  const handleNameChange = (e) => {
    const newName = e.target.value;
    const newSlug = generateSlug(newName);
    setProductSlug(newSlug);
  };


  /* discetion handel */
  const [descriptionContent, setDescriptionContent] = useState('');
  const contentFieldChanaged = (data) => {
    setDescriptionContent(data)
  }
  const onSubmit = async (editdata) => {
    try {
      const images = editdata?.images;
      const response = await handel_Images(images)
      if (response?.data) {
        const setData = {
          title: editdata?.title,
          slug: productSlug,
          categorie_id: editdata?.categorie_id, images: response.data.secure_url, description: descriptionContent, id: data?._id
        }
        await updateBlog(setData)
      } else {
        const setData = {
          title: editdata?.title,
          slug: productSlug, categorie_id: editdata?.categorie_id, description: descriptionContent, images: data?.images, id: data?._id
        }
        await updateBlog(setData)
      }
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success('Blog Edit !')
      setOpen({ type: false })
      reset()
    }
    if (isError) {
      toast.error('sorry deparment not add!')
    }

  }, [isError, isLoading, isSuccess,])

  return (
    <>{
      type && <div className='modal_product'
      >
        <section className="content-main blog_handel">
          <div className="row">
            <div className="col-12">
              <div className="content-header">
                <h2 className="content-title">Blog Edit</h2>
                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row"
               
                >
                  <div className="col-6">
                    <label htmlFor="product_name" className="form-label">Title <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" placeholder="Type here" className="form-control" id="product_name"
                      defaultValue={data?.title}
                      {...register("title")}
                      onChange={handleNameChange}
                    />
                    {errors?.title && (
                      <span className="form__error">{errors?.title.message}</span>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="slug" className="form-label">Slug <span style={{ color: 'red' }}>*</span></label>
                    <input type="text" placeholder="slug" className="form-control" id="slug"
                      value={productSlug ? productSlug : data?.slug}
                      onChange={handleNameChange}
                    />
                    {errors?.slug && (
                      <span className="form__error">{errors?.slug.message}</span>
                    )}
                  </div>
                  <div className="col-6 mb-4">
                    <label className="form-label">Category</label>
                    <select className="form-select" {...register("categorie_id")} >
                      <option className="form-label" value=''>Category Select</option>
                      {categories?.categorie?.map(categorie => <option value={categorie?._id} key={categorie?._id} selected={categorie?._id == data?.categorie_id?._id}>{categorie?.name}</option>)}
                    </select>
                    {errors?.categorie_id && (
                      <span className="form__error">{errors?.categorie_id.message}</span>
                    )}
                  </div>
                  <div className="col-6 mb-4">
                    <label htmlFor="images" className="form-label">Blog Images</label>
                    <input type="file" placeholder="Type here" className="form-control" id="images"
                      {...register("images")}
                    />
                    {data?.images && <img src={data?.images} style={{ width: '100px', height: '100px', borderRadius: '10px' }} />}
                  </div>
                  <div className="mb-4">
                    <label htmlFor='description' className="form-label"> Description</label>
                    <JoditEditor
                      ref={editor}
                      value={data?.description}
                      onChange={(newContent) => contentFieldChanaged(newContent)}
                    />
                  </div>

                </div>
                <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>

              </form>

            </div>
          </div>
        </section>

      </div>
    }
    </>
  )
}

export default BlogModalEdit