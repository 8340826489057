import React, { useEffect, useState } from 'react'
import { useDeleteMutation, useUpdateUserMutation } from '../../../app/services/user';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
function SingleUser({ data, role }) {
    const [action, setAction] = useState(false)
    const [updateUser, { isSuccess, isLoading }] = useUpdateUserMutation()
    const [deleteUser, { isSuccess: deleteSuccess, isLoading: deleteloading }] = useDeleteMutation()


    const UpdataUserHandel = async (data, id) => {
        const sentData = { approved: data, _id: id }
        await updateUser(sentData)
    }

    const deleteHandel = async (id, uid) => {
        const sentData = { _id: id, uid: uid }
        await deleteUser(sentData)
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Successful Update User')
        }
        if (deleteSuccess) {
            toast.success('Successful Delete User')
        }
    }, [isSuccess, deleteSuccess])
    return (
        <tr>
            <td style={{ textDecoration: 'underline', cursor: 'pointer' }}
            ><Link to={`/user/${data?._id}`}>{data?.name}</Link></td>
            {
                role == 'consumer' && <td>{data?.email}</td>
            }
            {
                role !== 'consumer' && <td>{data?.company_name}</td>
            }
            {
                role !== 'consumer' && <td>{data?.company_email}</td>
            }
            <td>{data?.city}</td>
            <td>{data?.state}</td>
            <td>{!data?.approved ? <span
                style={{ color: 'red' }}>pannding</span> : <span style={{ color: 'green' }}>approved</span>}</td>

            <td className="text-end">
                <td className='dropdown nav-item' >
                    <a onClick={() => setAction(!action)}>:</a>
                    <div className={`dropdown-menu dropdown-menu-end ${action ? 'show' : ''} `} aria-labelledby="dropdownAccount">
                        <a style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="dropdown-item"
                            onClick={() => UpdataUserHandel(!data?.approved, data?._id)}
                        >
                            {data?.approved ? <span
                                style={{ color: 'red' }}>Pannding</span> : 'Approved'}

                        </a>
                        <a tyle={{ cursor: deleteloading ? 'no-drop' : 'pointer' }} className="dropdown-item" onClick={() => deleteHandel(data?._id, data?.uid)}>
                            {data?.uid ? 'Delete' : 'Cancel'}
                        </a>
                    </div>
                </td>
            </td>
        </tr>
    )
}
export default SingleUser;

