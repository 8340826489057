import React, { useEffect } from 'react'
import { useUpdateCategoriesMutation } from '../../../../app/services/categories';
import { CategoriesSchema } from '../../../../helpers/validation/CategoriesSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import handel_Images from '../../HandelImages';

function CaregorieModalEdit({ modal, setOpen, data: departmentData }) {
    const { type, data } = modal;
    const [UpdateCategories, { isError, isLoading, isSuccess }] = useUpdateCategoriesMutation()
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(CategoriesSchema) });
    const onSubmit = async (value) => {
        console.log(value)
        const images = value?.images
        const response = await handel_Images(images)
        if (response) {
            const updateData = { ...value, id: data?._id, images: response?.data?.secure_url }
            await UpdateCategories(updateData)
        } else {
            const updateData = { ...value, id: data?._id, images: data?.images }
            await UpdateCategories(updateData)
        }
    }
    useEffect(() => {
        if (isSuccess) {
            toast.success('Caregorie Edit !')
            setOpen({ type: false })
            reset()
        }
        if (isError) {
            toast.error('sorry Caregorie not add!')
        }

    }, [isError, isLoading, isSuccess])

    console.log(data)


    return (
        <>{
            type && <div className='modal_product'>
                <section className="content-main">
                    <div className="row">
                        <div className="col-12">
                            <div className="content-header">
                                <h2 className="content-title">Categorie Add</h2>
                                <button onClick={() => setOpen({ type: false }, reset())}>X</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-4">
                                <label className="form-label">Department</label>
                                <select className="form-select"  {...register("development_id")}>
                                    {departmentData?.development?.map(development => <option value={development?._id} key={development?._id}
                                        selected={development?._id == data?.development_id?._id}
                                    >{development?.name}</option>)}
                                </select>
                                {errors?.development_id && (
                                    <span className="form__error">{errors?.development_id.message}</span>
                                )}
                            </div>
                            <div className="mb-4">
                                <label htmlFor="product_name" className="form-label">Category Name</label>
                                <input type="text" placeholder="Categoty Names" className="form-control" id="product_name"
                                    defaultValue={data?.name}
                                    {...register("name")}
                                />
                                {errors?.name && (
                                    <span className="form__error">{errors?.name.message}</span>
                                )}
                            </div>
                            {/* images handel  */}
                            <div className="mb-4">
                                <label htmlFor="images" className="form-label">Category Images</label>
                                <input type="file" placeholder="Type here" className="form-control" id="images"
                                    {...register("images")}
                                />
                                {data?.images && (
                                    <img src={data?.images} style={{ width: '100px', height: '100px' }} />
                                )}
                            </div>
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Submit</button>
                        </form>
                    </div>
                </section >

            </div >
        }
        </>
    )
}

export default CaregorieModalEdit