export const selectFlItemCounter = (fl_Item) => {
    let itemQuntity = 0;
    fl_Item?.forEach(fl => itemQuntity += fl?.item)
    return itemQuntity;
}

export const totalPrices = (cartItems) => {
    let totalPrices = 0;
    cartItems.forEach((items) => items?.selectFl?.forEach(fl => totalPrices += fl?.item * items?.singlePrices));
    return totalPrices.toFixed(2);
};

export const consumerPices = (fl_Item, deals, singlePrices) => {
    const quantity = fl_Item
    const { item, prices } = deals;
    if (item) {
        const discount = (singlePrices * item) - prices;
        const palanCount = quantity / item
        const stringValue = palanCount.toString();
        const parts = stringValue.split('.');
        const beforeDecimal = Number(parts[0]);
        const afterDecimal = Number(parts[1]);
        const afterPricesHandel = afterDecimal ? Number(`0.${afterDecimal}`) * item : 0
        const totalDiscount = discount * beforeDecimal;
        const totalPrices = (beforeDecimal * item) * singlePrices;
        const totalafterPrices = afterPricesHandel * singlePrices;
        const result = Number((totalPrices + totalafterPrices) - totalDiscount);
        return result.toFixed(2);
    } else {
        const totalPrices = Number(fl_Item * singlePrices)
        return totalPrices.toFixed(2)
    }
}

export const salesTax = (prices) => {
    const number = prices * 0.07;
    return number.toFixed(2);
}




