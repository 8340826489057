import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRef } from 'react';
import "jodit";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
import { toast } from 'react-toastify';
import { useQueryCategoriesMutation } from '../../../app/services/categories';
import { useQuerySubCategoriesMutation } from '../../../app/services/subCategories';
import { useQueryChildSubCategoriesMutation } from '../../../app/services/childsubCategories';
import { UpdateProductsSchema } from '../../../helpers/validation/ProductsSchema';
import { useGetDepartmentQuery } from '../../../app/services/department';
import { useUpdateProductMutation } from '../../../app/services/product';
import { useGetCategoriesQuery } from '../../../app/services/categories';
import { useGetSubCategoriesQuery } from '../../../app/services/subCategories';
import { useGetChildSubCategoriesQuery } from '../../../app/services/childsubCategories';
import ExtraCostAddModel from '../modal/product/ExtraCostAddModel';
import { useGetAllbrandQuery } from '../../../app/services/brand';
import { Fragment } from 'react';
function ProductEdit({ modal, setOpen }) {
    const { data: productData } = modal;
    const [extraOpen, setExtraOpen] = useState(false)
    const [productSlug, setProductSlug] = useState(productData?.slug);

    const editor = useRef(null);
    const [sameWord, setSameWord] = useState('')
    const [porductDetails, setPorductDetails] = useState('');
    const [content, setContent] = useState('');
    const contentFieldChanaged = (data) => {
        setContent(data)
    }
    const PorductDetailscontentField = (data) => {
        setPorductDetails(data)
    }
    /* slug */
    const generateSlug = (text) => {
        return text
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '');
    };
    const handleNameChange = (e) => {
        const newName = e.target.value;
        const newSlug = generateSlug(newName);
        setProductSlug(newSlug);
    };

    const specificWordCount = (content.match(new RegExp(sameWord, 'gi')) || []).length;
    const [UpdateProduct, { isError, isLoading, isSuccess }] = useUpdateProductMutation()
    const { data } = useGetDepartmentQuery()
    const { data: brand } = useGetAllbrandQuery()
    const [QueryCategories, { data: categorieData }] = useQueryCategoriesMutation()
    const [QuerySubCategories, { data: subCategorieData }] = useQuerySubCategoriesMutation()
    const [QueryChildSub, { data: child }] = useQueryChildSubCategoriesMutation()
    const selectDepartment = async (id) => {
        await QueryCategories(id)
    }
    const selectCategory = async (id) => {
        await QuerySubCategories(id)
    }
    const selectSubCategory = async (id) => {
        await QueryChildSub(id)
    }

    const [flavior, setFlavior] = useState(productData?.images)
    const [selling, setSelling] = useState()

    const handleChangeText = (index, event) => {
        const value = event?.target?.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], name: value };
            return updatedFlavior;
        });
    }
    const handleChangeImages = (image, index) => {
        if (image) {
            setFlavior(prevState => {
                const updatedFlavior = [...prevState];
                updatedFlavior[index] = { ...updatedFlavior[index], image: [...updatedFlavior[index].image, ...image] };
                return updatedFlavior;
            });
        }
    }
    const handleImageTitle = (index, event) => {
        const value = event.target.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], imagesTitle: value };
            return updatedFlavior;
        });
    }
    const handleImageAlterText = (index, event) => {
        const value = event.target.value;
        setFlavior(prevState => {
            const updatedFlavior = [...prevState];
            updatedFlavior[index] = { ...updatedFlavior[index], imageAlterText: value };
            return updatedFlavior;
        });
    }


    const handleFileChange = (e, index) => {
        const files = e.target.files;
        const uploadedImages = [];
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            const filename = uuidv4();
            formData.append('file', files[i]);
            formData.append('public_id', filename);
            formData.append('upload_preset', 'p36dm5k4');
            axios
                .post('https://api.cloudinary.com/v1_1/retwho/image/upload', formData)
                .then((response) => {
                    uploadedImages.push(response.data.secure_url);
                    if (uploadedImages.length === files.length) {
                        handleChangeImages(uploadedImages, index)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const Deleteflavior = (id) => {
        setFlavior(flavior => flavior.filter((data, i) => data?._id !== id));
    }


    const flaviorItemsDelete = (flaviorIndex, imageIndex) => {
        setFlavior((prevState) => {
            const updatedFlavior = JSON.parse(JSON.stringify(prevState));
            updatedFlavior[flaviorIndex].image = updatedFlavior[flaviorIndex].image.filter(
                (_, i) => i !== imageIndex
            );
            return updatedFlavior;
        });
    };




    /* =================== Retailer Deals Ppcs handel  ===================  */
    const [dealsPcsRetailer, setDealsPcsRetailer] = useState(productData?.retailer_deals[0]?.items)
    const handleDealsPcsPricesRetailer = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleDealsItemsetPcsRetailer = (index, event) => {
        const value = event.target.value;
        setDealsPcsRetailer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }

    const DeleteDealsPcsRetailer = (index) => {
        setDealsPcsRetailer(dealsPcsRetailer => dealsPcsRetailer.filter((_, i) => i !== index));
    }
    /* =================== Wholesaler Deals Ppcs handel  ===================  */
    const [dealsPcsWholesaler, setDealsPcsWholesaler] = useState(productData?.wholesaler_deals[0]?.items)

    const handleDealsPcsPricesWholesaler = (index, event) => {
        const value = event.target.value;
        setDealsPcsWholesaler(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleItemsetDealsPcsWholesaler = (index, event) => {
        const value = event.target.value;
        setDealsPcsWholesaler(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }

    const DeleteDealsWholesaler = (index) => {
        setDealsPcsWholesaler(dealsPcsWholesaler => dealsPcsWholesaler.filter((_, i) => i !== index));
    }
    /* =================== Consumer Deals Ppcs handel  ===================  */
    const [dealsPcsConsumer, setDealsPcsConsumer] = useState(productData?.consumer_deals[0]?.items)

    const handleDealsPcsPricesConsumer = (index, event) => {
        const value = event.target.value;
        setDealsPcsConsumer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], prices: value };
            return updatedPcs;
        });
    }
    const handleItemsetDealsPcsConsumer = (index, event) => {
        const value = event.target.value;
        setDealsPcsConsumer(prevState => {
            const updatedPcs = [...prevState];
            updatedPcs[index] = { ...updatedPcs[index], item: value };
            return updatedPcs;
        });
    }

    const DeleteDealsPcsConsumer = (index) => {
        setDealsPcsConsumer(dealsPcsConsumer => dealsPcsConsumer.filter((_, i) => i !== index));
    }

    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(UpdateProductsSchema) });
    const onSubmit = async (data) => {
        const updateData = {
            product_name: data?.product_name,
            slug: productSlug,
            upc: data?.upc,
            upcBox: data?.upcBox,
            cost: data?.cost,
            sku: data?.sku,
            development_id: data?.development_id,
            categorie_id: data?.categorie_id,
            sub_id: data?.sub_id,
            childSub_id: data?.childSub_id,
            brand_id: data?.brand_id,
            status: data?.status,
            quantity: productData?.quantity,
            images: flavior,
            imagesTitle: data?.imagesTitle,
            imageAlterText: data?.imageAlterText,
            saleing_Price: data?.saleing_Price,
            retailer_saleing_Price: data?.retailer_saleing_Price,
            wholesaler_saleing_Price: data?.wholesaler_saleing_Price,
            consumer_saleing_Price: data?.consumer_saleing_Price,
            price: [
                { name: 'xs', price: data?.xs },
                { name: 'sm', price: data?.sm },
                { name: 'md', price: data?.md },
                { name: 'lg', price: data?.lg },
                { name: 'xl', price: data?.xl },
                { name: 'xl_2', price: data?.xl_2 },
                { name: 'xl_3', price: data?.xl_3 },
                { name: 'xl_4 ', price: data?.xl_4 },
                { name: 'xl_5', price: data?.xl_5 },
                { name: 'xl_6', price: data?.xl_6 },
                { name: 'xl_7', price: data?.xl_7 },
            ],
            retailer_price: [
                { name: 'xs', price: data?.retailer_xs },
                { name: 'sm', price: data?.retailer_sm },
                { name: 'md', price: data?.retailer_md },
                { name: 'lg', price: data?.retailer_lg },
                { name: 'xl', price: data?.retailer_xl },
                { name: 'xl_2', price: data?.retailer_xl_2 },
                { name: 'xl_3', price: data?.retailer_xl_3 },
                { name: 'xl_4 ', price: data?.retailer_xl_4 },
                { name: 'xl_5', price: data?.retailer_xl_5 },
                { name: 'xl_6', price: data?.retailer_xl_6 },
                { name: 'xl_7', price: data?.retailer_xl_7 },
            ],
            wholesaler_price: [
                { name: 'xs', price: data?.wholesaler_xs },
                { name: 'sm', price: data?.wholesaler_sm },
                { name: 'md', price: data?.wholesaler_md },
                { name: 'lg', price: data?.wholesaler_lg },
                { name: 'xl', price: data?.wholesaler_xl },
                { name: 'xl_2', price: data?.wholesaler_xl_2 },
                { name: 'xl_3', price: data?.wholesaler_xl_3 },
                { name: 'xl_4 ', price: data?.wholesaler_xl_4 },
                { name: 'xl_5', price: data?.wholesaler_xl_5 },
                { name: 'xl_6', price: data?.wholesaler_xl_6 },
                { name: 'xl_7', price: data?.wholesaler_xl_7 },
            ],
            consumer_price: [
                { name: 'xs', price: data?.consumer_xs },
                { name: 'sm', price: data?.consumer_sm },
                { name: 'md', price: data?.consumer_md },
                { name: 'lg', price: data?.consumer_lg },
                { name: 'xl', price: data?.consumer_xl },
                { name: 'xl_2', price: data?.consumer_xl_2 },
                { name: 'xl_3', price: data?.consumer_xl_3 },
                { name: 'xl_4 ', price: data?.consumer_xl_4 },
                { name: 'xl_5', price: data?.consumer_xl_5 },
                { name: 'xl_6', price: data?.consumer_xl_6 },
                { name: 'xl_7', price: data?.consumer_xl_7 },
            ],
            tag: data?.tag,
            pcs: data?.pcs,
            meta_title: data?.meta_title,
            meta_description: data?.meta_description,
            sale_retailer: [
                {
                    status: data?.retailer_sales_offer_status,
                    type: data?.retailer_sales_offer_type,
                    offer: data?.retailer_sales_offer_amount,
                    amount: data?.retailer_sales_offer_totatPrices,
                    color: data?.retailer_sales_offer_color,
                    content: data?.retailer_sales_offer_content,
                }
            ],
            retailer_deals: [
                {
                    title: data?.retailer_deals_title,
                    status: data?.retailer_deals_status,
                    items: dealsPcsRetailer,
                }
            ],
            sale_wholesaler: [
                {
                    status: data?.wholesaler_sales_offer_status,
                    type: data?.wholesaler_sales_offer_type,
                    offer: data?.wholesaler_sales_offer_amount,
                    amount: data?.wholesaler_sales_offer_totatPrices,
                    color: data?.wholesaler_sales_offer_color,
                    content: data?.wholesaler_sales_offer_content,
                }
            ],
            wholesaler_deals: [
                {
                    title: data?.wholesaler_deals_title,
                    status: data?.wholesaler_deals_status,
                    items: dealsPcsWholesaler
                }
            ],
            sale_consumer: [
                {
                    status: data?.consumer_sales_offer_status,
                    type: data?.consumer_sales_offer_type,
                    offer: data?.consumer_sales_offer_amount,
                    amount: data?.consumer_sales_offer_totatPrices,
                    color: data?.consumer_sales_offer_color,
                    content: data?.consumer_sales_offer_content,
                }
            ],
            consumer_deals: [
                {
                    title: data?.consumer_deals_title,
                    status: data?.consumer_deals_status,
                    items: dealsPcsConsumer,
                }
            ],

            coupon: [
                {
                    type: data?.coupon_offer_type,
                    offer: data?.coupon_offer,
                    amount: data?.coupon_Offer_amount,
                }
            ],
            promo: [
                {
                    type: data?.promo_offer_type,
                    offer: data?.promo_code,
                    amount: data?.promo_amount,
                }
            ],
            deal: [
                {
                    type: data?.deal_offer_type,
                    offer: data?.deal_time_type,
                    amount: data?.deal_amount,
                }
            ],
            description: content,
            product_ditails: porductDetails,
            new_quantity: data?.new_quantity,
            quantity_action: data?.quantity_action,
            stock: productData?.stock,
            totalItems: productData?.totalItems
        }
        const productUpdateValue = { _id: productData?._id, updateData }
        await UpdateProduct(productUpdateValue)
    }

    const status = [
        {
            status_name: 'active',
            type: true,
        },
        {
            status_name: 'inactive',
            type: false,
        }
    ]
    const calculator = [
        {
            name: '$',
            type: false,

        },
        {
            name: '%',
            type: true,

        }
    ]
    const calendar = [
        {
            name: 'day',
            type: false,

        },
        {
            name: 'weekday',
            type: true,

        },
        {
            name: 'month',
            type: true,

        }
    ]

    useEffect(() => {
        if (isSuccess) {
            toast.success('Success Add !')
            setOpen({ type: false, data: null })
            reset()
        }
        if (isError) {
            toast.error('Success add!')
        }
    }, [isError, isLoading, isSuccess])

    return (
        <>
            <section className="content-main">
                <div className="row">
                    <div className="col-12">
                        <button onClick={() => setOpen({ type: false, data: null }, reset())} className="btn btn-primary btn-sm rounded">Back</button>
                        <div className="content-header">
                            <div>
                                <h2 className="content-title">Product Edit</h2>
                            </div>
                            <div>
                                <a onClick={() => setExtraOpen(true)} className="btn btn-primary btn-sm rounded">Product Types</a>
                            </div>
                        </div>

                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12 mb-2">
                            <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-primary btn-sm rounded">Update</button>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="product_title" className="form-label">Product Title <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" placeholder="Product Title" className="form-control" id="product_title" {...register("product_name")}
                                                defaultValue={productData?.product_name}
                                                onChange={handleNameChange}
                                            />
                                            {errors?.product_name && (
                                                <span className="form__error">{errors?.product_name.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <label htmlFor="slug " className="form-label">Slug  <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" placeholder="product-slug" className="form-control" id="slug "
                                                value={productSlug ? productSlug : productData?.slug}
                                                {...register("slug")}
                                                disabled
                                            />
                                            {errors?.slug && (
                                                <span className="form__error">{errors?.slug.message}</span>
                                            )}
                                        </div>

                                    </div>

                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_UPC" className="form-label">UPC (Single)</label>
                                            <input type="text" placeholder="UPC" className="form-control" id="product_UPC"
                                                {...register("upc")}
                                                defaultValue={productData?.upc}
                                            />
                                            {errors?.upc && (
                                                <span className="form__error">{errors?.upc.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_SKU" className="form-label">SKU</label>
                                            <input type="text" placeholder="SKU" className="form-control" id="product_SKU"
                                                {...register("sku")}
                                                defaultValue={productData?.sku}
                                            />
                                            {errors?.sku && (
                                                <span className="form__error">{errors?.sku.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_upc_box" className="form-label">UPC (Box) </label>
                                            <input type="text" placeholder="UPC (Box)" className="form-control" id="product_upc_box"
                                                {...register("upcBox")}
                                                defaultValue={productData?.upcBox}
                                            />
                                            {errors?.upcBox && (
                                                <span className="form__error">{errors?.upcBox.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Department <span style={{ color: 'red' }}>*</span></label>
                                                <select className="form-select" {...register("development_id")} onChange={(e) => selectDepartment(e.target.value)}>
                                                    {
                                                        productData?.development_id?.name ? <option value={productData?.development_id?._id} selected>{productData?.development_id?.name}</option> : <option value=''>Department Select</option>
                                                    }
                                                    {data?.development?.map(data => <option value={data?._id} key={data?._id}
                                                        selected={data?._id == productData?.development_id?._id}
                                                    >{data?.name}</option>)}
                                                </select>
                                                {errors?.development_id && (
                                                    <span className="form__error">{errors?.development_id.message}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Category</label>
                                                <select className="form-select" {...register("categorie_id")} onChange={(e) => selectCategory(e.target.value)}>
                                                    {productData?.categorie_id?.name ? <option value={productData?.categorie_id?._id} selected >{productData?.categorie_id?.name}</option> : <option value=''>Category Select</option>
                                                    }
                                                    {categorieData?.categorie?.map(data => <option value={data?._id} key={data?._id}
                                                    >{data?.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Sub Category</label>
                                                <select className="form-select" {...register("sub_id")} onChange={(e) => selectSubCategory(e.target.value)}>

                                                    {productData?.sub_id?.name ? <option value={productData?.sub_id?._id} selected>{productData?.sub_id?.name}</option> : <option value=''>Sub Category Select</option>
                                                    }

                                                    {subCategorieData?.subCategorie?.map(data => <option value={data?._id} key={data?._id}
                                                    >{data?.name
                                                        }</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-3">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Child Sub Category</label>
                                                <select className="form-select" {...register("childSub_id")}>
                                                    {productData?.childSub_id?.name ? <option value={productData?.childSub_id?._id} selected>{productData?.childSub_id?.name}</option> : <option value=''>Child Sub Category Select</option>
                                                    }
                                                    {child?.childSub?.map(data => <option value={data?._id} key={data?._id}
                                                        selected={data?._id == productData?.childSub_id?._id}
                                                    >{data?.name}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Brand</label>
                                                <select className="form-select" {...register("brand_id")}>
                                                    {
                                                        brand?.map(data => <option value={data?._id} selected={data?._id == productData?.brand_id}>{data?.title}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Status</label>
                                                <select className="form-select" {...register("status")}>
                                                    {
                                                        status?.map(data => <option value={data?.type} selected={data?.type == productData?.status}>{data?.status_name}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gx-2">
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="product_stock" className="form-label">Stock</label>
                                            <input type="number" placeholder="product_stock" className="form-control" id="product_stock"
                                                {...register("quantity")}
                                                defaultValue={productData?.stock}
                                                style={{ cursor: 'no-drop' }}
                                                disabled

                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="product_stock" className="form-label">Total Pcs</label>
                                            <input type="number" placeholder="product_stock" className="form-control" id="product_stock"
                                                defaultValue={productData?.totalItems}
                                                style={{ cursor: 'no-drop' }}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="pcs" className="form-label">Pcs <span style={{ color: 'red' }}>*</span></label>
                                            <input type="number" placeholder="pcs" className="form-control" id="pcs"
                                                {...register("pcs")}
                                                defaultValue={productData?.pcs}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="cost" className="form-label">Cost <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" placeholder="cost" className="form-control" id="cost"
                                                {...register("cost")}
                                                defaultValue={productData?.cost}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="product_Quantity" className="form-label">Quantity <span style={{ color: 'red' }}>*</span> </label>
                                            <input type="number" placeholder="product_Quantity" className="form-control" id="product_Quantity"
                                                {...register("quantity")}
                                                defaultValue={productData?.quantity}
                                                style={{ cursor: 'no-drop' }}
                                                disabled

                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <label htmlFor="New_Quantity" className="form-label">New Quantity</label>
                                            <input type="number" placeholder="New_Quantity" className="form-control" id="New_Quantity"
                                                {...register("new_quantity")}
                                                defaultValue={0}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            <div className="mb-3">
                                                <label className="form-label">Quantity Action Type</label>
                                                <select className="form-select"
                                                    {...register("quantity_action")}
                                                >
                                                    <option value='+' selected>+</option>
                                                    <option value='-'>-</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h1 className='m-0 p-0 text-end px-4'><span onClick={() => setFlavior([...flavior, { name: '', image: [] }])} style={{ cursor: 'pointer' }}>+</span></h1>
                                <div className="card-body">
                                    {
                                        flavior.map((flaviorData, flaviorIndex) =>
                                            <div key={flaviorData?._id}>
                                                <div className="row gx-3">
                                                    <div className="col-md-5 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Flavior Name </label>
                                                        <input
                                                            onChange={(event) => handleChangeText(flaviorIndex, event)} type="text"
                                                            defaultValue={flaviorData?.name}
                                                            placeholder="Flavior Name" className="form-control" id="product_Flavior"
                                                        />
                                                    </div>
                                                    <div className="col-md-5 mb-3">
                                                        <label htmlFor="product_images" className="form-label">Images</label>
                                                        <input type="file" placeholder="Images" className="form-control" id="product_images"
                                                            onChange={(e) => handleFileChange(e, flaviorIndex)}
                                                            accept="image/jpeg,image/gif,image/png,image/webp"
                                                            multiple
                                                        />
                                                    </div>
                                                    <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                        <a className="form-control btn  font-sm btn-light rounded" onClick={() => Deleteflavior(flaviorData?._id)}><i className="material-icons md-delete_forever"></i> Delete</a>

                                                    </div>
                                                    <div className='main_single_images_flex'>
                                                        {
                                                            flaviorData?.image?.map((value, imageindex) => <Fragment key={imageindex}>
                                                                <div className='single_images_delete'>
                                                                    <img className="img-sm img-thumbnail" alt="Item" src={value} />
                                                                    <span onClick={() => flaviorItemsDelete(flaviorIndex, imageindex)}><i className="material-icons md-delete_forever"></i></span>
                                                                </div>
                                                            </Fragment>

                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row gx-2">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="images-title" className="form-label" >Image Title</label>
                                                        <input type="text" placeholder="Image Title" className="form-control" id="images-title"
                                                            defaultValue={flaviorData?.imagesTitle}
                                                            onBlur={(event) => handleImageTitle(flaviorIndex, event)}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="images-title-alter-text" className="form-label">Image Alter Text</label>
                                                        <input type="text" placeholder="Image Alter Text" className="form-control" id="images-title-alter-text"
                                                            defaultValue={flaviorData?.imageAlterText}
                                                            onBlur={(event) => handleImageAlterText(flaviorIndex, event)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor='description' className="form-label"> Product Details</label>
                                <JoditEditor
                                    ref={editor}
                                    value={productData?.product_ditails}
                                    onChange={(newContent) => PorductDetailscontentField(newContent)}
                                />
                            </div>
                        </div>




                        
                        {/* Offline Prices  */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Offline Price</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="Saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" placeholder="Saleing Price" className="form-control" id="Saleing_Price"
                                                {...register("saleing_Price")}
                                                onChange={(e) => setSelling(e.target.value)}
                                                defaultValue={productData?.saleing_Price}

                                            />
                                            {errors?.saleing_Price && (
                                                <span className="form__error">{errors?.saleing_Price.message}</span>
                                            )}
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="price-xs" className="form-label" >Price xs </label>
                                            <input type="number" placeholder="sm" className="form-control" id="price-xs"
                                                {...register("xs")}
                                                defaultValue={productData?.price[0]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="price-sm" className="form-label" >Price sm</label>
                                            <input type="number" placeholder="sm" className="form-control" id="price-sm"
                                                {...register("sm")}
                                                defaultValue={productData?.price[1]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_md" className="form-label">Price md</label>
                                            <input type="number" placeholder="md" className="form-control" id="product_md"
                                                {...register("md")}
                                                defaultValue={productData?.price[2]?.price}

                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_lg" className="form-label">Price lg</label>
                                            <input type="number" placeholder="lg" className="form-control" id="product_lg"
                                                {...register("lg")}
                                                defaultValue={productData?.price[3]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_xl" className="form-label">Price xl</label>
                                            <input type="number" placeholder="xl" className="form-control" id="product_xl"
                                                {...register("xl")}
                                                defaultValue={productData?.price[4]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_2xl" className="form-label">Price 2xl</label>
                                            <input type="number" placeholder="2xl" className="form-control" id="product_2xl"
                                                {...register("xl_2")}
                                                defaultValue={productData?.price[5]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_3xl" className="form-label">Price 3xl</label>
                                            <input type="number" placeholder="3xl" className="form-control" id="product_3xl"
                                                {...register("xl_3")}
                                                defaultValue={productData?.price[6]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_4xl" className="form-label">Price 4xl</label>
                                            <input type="number" placeholder="4xl" className="form-control" id="product_4xl"
                                                {...register("xl_4")}
                                                defaultValue={productData?.price[7]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_5xl" className="form-label">Price 5xl</label>
                                            <input type="number" placeholder="5xl" className="form-control" id="product_5xl"
                                                {...register("xl_5")}
                                                defaultValue={productData?.price[8]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_6xl" className="form-label">Price 6xl</label>
                                            <input type="number" placeholder="6xl" className="form-control" id="product_6xl"
                                                {...register("xl_6")}
                                                defaultValue={productData?.price[9]?.price}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label htmlFor="product_7xl" className="form-label">Price 7xl</label>
                                            <input type="number" placeholder="7xl" className="form-control" id="product_7xl"
                                                {...register("xl_7")}
                                                defaultValue={productData?.price[10]?.price}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Retailer Prices  */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Retailer</h3>
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Reguler Prices</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Saleing Price" className="form-control" id="retailer_saleing_Price"
                                                    {...register("retailer_saleing_Price")}
                                                    onChange={(e) => setSelling(e.target.value)}
                                                    defaultValue={productData?.retailer_saleing_Price}

                                                />
                                                {errors?.retailer_saleing_Price && (
                                                    <span className="form__error">{errors?.retailer_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_price-xs" className="form-label" >Price xs </label>
                                                <input type="number" placeholder="sm" className="form-control" id="retailer_price-xs"
                                                    {...register("retailer_xs")}
                                                    defaultValue={productData?.retailer_price[0]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_price-sm" className="form-label" >Price sm</label>
                                                <input type="number" placeholder="sm" className="form-control" id="retailer_price-sm"
                                                    {...register("retailer_sm")}
                                                    defaultValue={productData?.retailer_price[1]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_md" className="form-label">Price md</label>
                                                <input type="number" placeholder="md" className="form-control" id="retailer_product_md"
                                                    {...register("retailer_md")}
                                                    defaultValue={productData?.retailer_price[2]?.price}

                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_lg" className="form-label">Price lg</label>
                                                <input type="number" placeholder="lg" className="form-control" id="retailer_product_lg"
                                                    {...register("retailer_lg")}
                                                    defaultValue={productData?.retailer_price[3]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_xl" className="form-label">Price xl</label>
                                                <input type="number" placeholder="xl" className="form-control" id="retailer_product_xl"
                                                    {...register("retailer_xl")}
                                                    defaultValue={productData?.retailer_price[4]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_2xl" className="form-label">Price 2xl</label>
                                                <input type="number" placeholder="2xl" className="form-control" id="retailer_product_2xl"
                                                    {...register("retailer_xl_2")}
                                                    defaultValue={productData?.retailer_price[5]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_3xl" className="form-label">Price 3xl</label>
                                                <input type="number" placeholder="3xl" className="form-control" id="retailer_product_3xl"
                                                    {...register("retailer_xl_3")}
                                                    defaultValue={productData?.retailer_price[6]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_4xl" className="form-label">Price 4xl</label>
                                                <input type="number" placeholder="4xl" className="form-control" id="retailer_product_4xl"
                                                    {...register("retailer_xl_4")}
                                                    defaultValue={productData?.retailer_price[7]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_5xl" className="form-label">Price 5xl</label>
                                                <input type="number" placeholder="5xl" className="form-control" id="retailer_product_5xl"
                                                    {...register("retailer_xl_5")}
                                                    defaultValue={productData?.retailer_price[8]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_6xl" className="form-label">Price 6xl</label>
                                                <input type="number" placeholder="6xl" className="form-control" id="retailer_product_6xl"
                                                    {...register("retailer_xl_6")}
                                                    defaultValue={productData?.retailer_price[9]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="retailer_product_7xl" className="form-label">Price 7xl</label>
                                                <input type="number" placeholder="7xl" className="form-control" id="retailer_product_7xl"
                                                    {...register("retailer_xl_7")}
                                                    defaultValue={productData?.retailer_price[10]?.price}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("retailer_sales_offer_status")}
                                                    >
                                                        {
                                                            status?.map((data, index) => <option key={index} value={data?.type}
                                                                selected={productData?.sale_retailer[0]?.status == data?.type}

                                                            >{data?.status_name}</option>)
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("retailer_sales_offer_type")}
                                                    >

                                                        {
                                                            calculator?.map((data, index) => <option key={index} value={data?.name}
                                                                selected={productData?.sale_retailer[0]?.type == data?.name}

                                                            >{data?.name}</option>)

                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("retailer_sales_offer_amount")}
                                                    defaultValue={productData?.sale_retailer[0]?.offer}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("retailer_sales_offer_totatPrices")}
                                                    defaultValue={productData?.sale_retailer[0]?.amount}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferconten" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Content" className="form-control"
                                                    id="saleofferconten"
                                                    {...register("retailer_sales_offer_content")}
                                                    defaultValue={productData?.sale_retailer[0]?.content}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferColor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleofferColor"
                                                    {...register("retailer_sales_offer_color")}
                                                    defaultValue={productData?.sale_retailer[0]?.color}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsRetailer([...dealsPcsRetailer, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("retailer_deals_title")}
                                                            defaultValue={productData?.retailer_deals[0]?.title}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("retailer_deals_status")}
                                                            >
                                                                {
                                                                    status?.map((data, index) => <option key={index} value={data?.type}
                                                                        selected={productData?.retailer_deals[0]?.status == data?.type}

                                                                    >{data?.status_name}</option>)
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsPcsRetailer?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleDealsItemsetPcsRetailer(index, event)}
                                                                defaultValue={data?.item}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsPcsPricesRetailer(index, event)}
                                                                    defaultValue={data?.prices}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteDealsPcsRetailer(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>

                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Wholesaler Prices  */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Wholesaler Price</h3>
                                <div>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Saleing Price" className="form-control" id="wholesaler_saleing_Price"
                                                    {...register("wholesaler_saleing_Price")}
                                                    onChange={(e) => setSelling(e.target.value)}
                                                    defaultValue={productData?.wholesaler_saleing_Price}

                                                />
                                                {errors?.wholesaler_saleing_Price && (
                                                    <span className="form__error">{errors?.wholesaler_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_price-xs" className="form-label" >Price xs </label>
                                                <input type="number" placeholder="sm" className="form-control" id="wholesaler_price-xs"
                                                    {...register("wholesaler_xs")}
                                                    defaultValue={productData?.wholesaler_price[0]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_price-sm" className="form-label" >Price sm</label>
                                                <input type="number" placeholder="sm" className="form-control" id="wholesaler_price-sm"
                                                    {...register("wholesaler_sm")}
                                                    defaultValue={productData?.wholesaler_price[1]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_md" className="form-label">Price md</label>
                                                <input type="number" placeholder="md" className="form-control" id="wholesaler_product_md"
                                                    {...register("wholesaler_md")}
                                                    defaultValue={productData?.wholesaler_price[2]?.price}

                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_lg" className="form-label">Price lg</label>
                                                <input type="number" placeholder="lg" className="form-control" id="wholesaler_product_lg"
                                                    {...register("wholesaler_lg")}
                                                    defaultValue={productData?.wholesaler_price[3]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_xl" className="form-label">Price xl</label>
                                                <input type="number" placeholder="xl" className="form-control" id="wholesaler_product_xl"
                                                    {...register("wholesaler_xl")}
                                                    defaultValue={productData?.wholesaler_price[4]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_2xl" className="form-label">Price 2xl</label>
                                                <input type="number" placeholder="2xl" className="form-control" id="wholesaler_product_2xl"
                                                    {...register("wholesaler_xl_2")}
                                                    defaultValue={productData?.wholesaler_price[5]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_3xl" className="form-label">Price 3xl</label>
                                                <input type="number" placeholder="3xl" className="form-control" id="wholesaler_product_3xl"
                                                    {...register("wholesaler_xl_3")}
                                                    defaultValue={productData?.wholesaler_price[6]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_4xl" className="form-label">Price 4xl</label>
                                                <input type="number" placeholder="4xl" className="form-control" id="wholesaler_product_4xl"
                                                    {...register("wholesaler_xl_4")}
                                                    defaultValue={productData?.wholesaler_price[7]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_5xl" className="form-label">Price 5xl</label>
                                                <input type="number" placeholder="5xl" className="form-control" id="wholesaler_product_5xl"
                                                    {...register("wholesaler_xl_5")}
                                                    defaultValue={productData?.wholesaler_price[8]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_6xl" className="form-label">Price 6xl</label>
                                                <input type="number" placeholder="6xl" className="form-control" id="wholesaler_product_6xl"
                                                    {...register("wholesaler_xl_6")}
                                                    defaultValue={productData?.wholesaler_price[9]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="wholesaler_product_7xl" className="form-label">Price 7xl</label>
                                                <input type="number" placeholder="7xl" className="form-control" id="wholesaler_product_7xl"
                                                    {...register("wholesaler_xl_7")}
                                                    defaultValue={productData?.wholesaler_price[10]?.price}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("wholesaler_sales_offer_status")}
                                                    >
                                                        {
                                                            status?.map((data, index) => <option key={index} value={data?.type}
                                                                selected={productData?.sale_wholesaler[0]?.status == data?.type}

                                                            >{data?.status_name}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("wholesaler_sales_offer_type")}
                                                    >
                                                        {
                                                            calculator?.map((data, index) => <option key={index} value={data?.name}
                                                                selected={productData?.sale_wholesaler[0]?.type == data?.name}

                                                            >{data?.name}</option>)

                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("wholesaler_sales_offer_amount")}
                                                    defaultValue={productData?.sale_wholesaler[0]?.offer}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("wholesaler_sales_offer_totatPrices")}
                                                    defaultValue={productData?.sale_wholesaler[0]?.amount}

                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffercontent" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Content" className="form-control"
                                                    id="saleoffercontent"
                                                    {...register("wholesaler_sales_offer_content")}
                                                    defaultValue={productData?.sale_wholesaler[0]?.content}

                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffercolor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleoffercolor"
                                                    {...register("wholesaler_sales_offer_color")}
                                                    defaultValue={productData?.sale_wholesaler[0]?.color}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsWholesaler([...dealsPcsWholesaler, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("wholesaler_deals_title")}
                                                            defaultValue={productData?.wholesaler_deals[0]?.title}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("wholesaler_deals_status")}
                                                            >
                                                                {
                                                                    status?.map((data, index) => <option key={index} value={data?.type}
                                                                        selected={productData?.wholesaler_deals[0]?.status == data?.type}

                                                                    >{data?.status_name}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsPcsWholesaler?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleItemsetDealsPcsWholesaler(index, event)}
                                                                defaultValue={data?.item}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsPcsPricesWholesaler(index, event)}
                                                                    defaultValue={data?.prices}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteDealsWholesaler(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>

                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* Consumer Prices  */}
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Consumer Price</h3>
                                <div>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_saleing_Price" className="form-label" >Selling Price <span style={{ color: 'red' }}>*</span></label>
                                                <input type="text" placeholder="Saleing Price" className="form-control" id="consumer_saleing_Price"
                                                    {...register("consumer_saleing_Price")}
                                                    defaultValue={productData?.consumer_saleing_Price}

                                                />
                                                {errors?.consumer_saleing_Price && (
                                                    <span className="form__error">{errors?.consumer_saleing_Price.message}</span>
                                                )}
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_price-xs" className="form-label" >Price xs </label>
                                                <input text="text" placeholder="sm" className="form-control" id="consumer_price-xs"
                                                    {...register("consumer_xs")}
                                                    defaultValue={productData?.consumer_price[0]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_price-sm" className="form-label" >Price sm</label>
                                                <input text="text" placeholder="sm" className="form-control" id="consumer_price-sm"
                                                    {...register("consumer_sm")}
                                                    defaultValue={productData?.consumer_price[1]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_md" className="form-label">Price md</label>
                                                <input text="text" placeholder="md" className="form-control" id="consumer_product_md"
                                                    {...register("consumer_md")}
                                                    defaultValue={productData?.consumer_price[2]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_lg" className="form-label">Price lg</label>
                                                <input text="text" placeholder="lg" className="form-control" id="consumer_product_lg"
                                                    {...register("consumer_lg")}
                                                    defaultValue={productData?.consumer_price[3]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_xl" className="form-label">Price xl</label>
                                                <input text="text" placeholder="xl" className="form-control" id="consumer_product_xl"
                                                    {...register("consumer_xl")}
                                                    defaultValue={productData?.consumer_price[4]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_2xl" className="form-label">Price 2xl</label>
                                                <input text="text" placeholder="2xl" className="form-control" id="consumer_product_2xl"
                                                    {...register("consumer_xl_2")}
                                                    defaultValue={productData?.consumer_price[5]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_3xl" className="form-label">Price 3xl</label>
                                                <input text="text" placeholder="3xl" className="form-control" id="consumer_product_3xl"
                                                    {...register("consumer_xl_3")}
                                                    defaultValue={productData?.consumer_price[6]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_4xl" className="form-label">Price 4xl</label>
                                                <input text="text" placeholder="4xl" className="form-control" id="consumer_product_4xl"
                                                    {...register("consumer_xl_4")}
                                                    defaultValue={productData?.consumer_price[7]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_5xl" className="form-label">Price 5xl</label>
                                                <input text="text" placeholder="5xl" className="form-control" id="consumer_product_5xl"
                                                    {...register("consumer_xl_5")}
                                                    defaultValue={productData?.consumer_price[8]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_6xl" className="form-label">Price 6xl</label>
                                                <input text="text" placeholder="6xl" className="form-control" id="consumer_product_6xl"
                                                    {...register("consumer_xl_6")}
                                                    defaultValue={productData?.consumer_price[9]?.price}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor="consumer_product_7xl" className="form-label">Price 7xl</label>
                                                <input text="text" placeholder="7xl" className="form-control" id="consumer_product_7xl"
                                                    {...register("consumer_xl_7")}
                                                    defaultValue={productData?.consumer_price[10]?.price}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sale offer prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Sale Offer:</h5>
                                    <div className="card-body">
                                        <div className="row gx-2">
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Status</label>
                                                    <select className="form-select"
                                                        {...register("consumer_sales_offer_status")}
                                                    >
                                                        {
                                                            status?.map((data, index) => <option key={index} value={data?.type}
                                                                selected={productData?.sale_consumer[0]?.status == data?.type}

                                                            >{data?.status_name}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <label className="form-label">Sale Offer Type</label>
                                                    <select className="form-select"
                                                        {...register("consumer_sales_offer_type")}
                                                    >
                                                        {
                                                            calculator?.map((data, index) => <option key={index} value={data?.name}
                                                                selected={productData?.sale_consumer[0]?.type == data?.name}

                                                            >{data?.name}</option>)

                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffer" className="form-label">Sale Offer Amount</label>
                                                <input text="text" placeholder="Sale Offer" className="form-control" id="saleoffer"
                                                    {...register("consumer_sales_offer_amount")}
                                                    defaultValue={productData?.sale_consumer[0]?.offer}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleofferprice" className="form-label">Sale Offer Total Price</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleofferprice"
                                                    {...register("consumer_sales_offer_totatPrices")}
                                                    defaultValue={productData?.sale_consumer[0]?.amount}

                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffercontent" className="form-label">Sale Offer Content</label>
                                                <input text="text" placeholder="Sale Offer Price" className="form-control"
                                                    id="saleoffercontent"
                                                    {...register("consumer_sales_offer_content")}
                                                    defaultValue={productData?.sale_consumer[0]?.content}

                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="saleoffercolor" className="form-label">Sale Offer Color (6 digit)</label>
                                                <input text="text" placeholder="Sale Offer Color" className="form-control"
                                                    id="saleofferColor"
                                                    {...register("consumer_sales_offer_color")}
                                                    defaultValue={productData?.sale_consumer[0]?.color}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* deals pcs prices */}
                                <div>
                                    <h5 className='m-0 p-0 px-4'>Deals (Pcs) :</h5>
                                    <div>
                                        <h1 className='m-0 p-0 text-end px-4'><span
                                            onClick={() => setDealsPcsConsumer([...dealsPcsConsumer, { item: '', prices: '' }])}
                                            style={{ cursor: 'pointer' }}>+</span></h1>
                                        <div className="card-body">
                                            <div>
                                                <div className="row gx-3">
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="product_Flavior" className="form-label" >Deals Title</label>
                                                        <input type="text" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                            {...register("consumer_deals_title")}
                                                            defaultValue={productData?.consumer_deals[0]?.title}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="mb-4">
                                                            <label className="form-label">Deals Status</label>
                                                            <select className="form-select"
                                                                {...register("consumer_deals_status")}
                                                            >
                                                                {
                                                                    status?.map((data, index) => <option key={index} value={data?.type}
                                                                        selected={productData?.consumer_deals[0]?.status == data?.type}

                                                                    >{data?.status_name}</option>)
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Deals show */}
                                                {
                                                    dealsPcsConsumer?.map((data, index) => <div key={index} className="row gx-3">
                                                        <div className="col-md-5 mb-3">
                                                            <label className="form-label" >Item</label>
                                                            <input type="number" placeholder="Deals title" className="form-control" id="product_Flavior"
                                                                onBlur={(event) => handleItemsetDealsPcsConsumer(index, event)}
                                                                defaultValue={data?.item}
                                                            />
                                                        </div>
                                                        <div className="col-md-5 mb-3">
                                                            <div className="mb-4">
                                                                <label className="form-label">Prices</label>
                                                                <input type="number" placeholder="Deals title" className="form-control"
                                                                    onBlur={(event) => handleDealsPcsPricesConsumer(index, event)}
                                                                    defaultValue={data?.prices}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2" style={{ marginTop: '25px' }}>
                                                            <a
                                                                onClick={() => DeleteDealsPcsConsumer(index)}
                                                                className="form-control btn  font-sm btn-light rounded" ><i class="material-icons md-delete_forever"></i> Delete</a>

                                                        </div>

                                                    </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_tag" className="form-label" >Tag</label>
                                            <input type="text" placeholder="Tag" className="form-control" id="product_tag"
                                                {...register("tag")}
                                                defaultValue={productData?.tag}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_meta-title" className="form-label">Meta Title</label>
                                            <input type="text" placeholder="Meta Title" className="form-control" id="product_meta-title"
                                                {...register("meta_title")}
                                                defaultValue={productData?.meta_title}

                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor='metadis' className="form-label">Meta Description</label>
                                        <textarea placeholder="Meta Description" className="form-control" rows="4" id='metadis'
                                            {...register("meta_description")}
                                            defaultValue={productData?.meta_description}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Coupon</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Coupon Offer Type</label>
                                                <select className="form-select"
                                                    {...register("coupon_offer_type")}
                                                >
                                                    {
                                                        calculator?.map((data, index) => <option key={index} value={data?.name} selected={data?.name == productData?.coupon[0]?.type}
                                                        >{data?.name} </option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="product_coupon" className="form-label">Coupon Offer</label>
                                            <select className="form-select"
                                                {...register("coupon_offer")}
                                            >
                                                {
                                                    calculator?.map((data, index) => <option key={index} value={data?.type} selected={data?.name == productData?.coupon[0]?.offer}
                                                    >{data?.name} </option>)

                                                }



                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="coupon_amount" className="form-label"> Offer amount </label>
                                            <input type="number"
                                                defaultValue={productData?.coupon[0]?.amount}
                                                {...register("coupon_Offer_amount")}
                                                className="form-control"
                                                id="coupon_amount"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Promo Code</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Promo Offer Type</label>
                                                <select className="form-select"
                                                    {...register("promo_offer_type")}
                                                >
                                                    {calculator?.map(data => <option value={data?.name} selected={data?.name == productData?.promo[0]?.type}
                                                    >{data?.name} </option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_amount" className="form-label">Promo Code</label>
                                            <input type="number" placeholder="Sale Offer" className="form-control" id="Promo_amount"
                                                {...register("promo_code")}
                                                defaultValue={productData?.promo[0]?.promo_code}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_Offer_Price" className="form-label">Offer amount</label>
                                            <input type="number" className="form-control" id="Promo_Offer_Price"
                                                {...register("promo_amount")}
                                                defaultValue={productData?.promo
                                                [0]?.amount}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <h3 className='m-0 p-0 px-4'>Deal</h3>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-md-4 mb-3">
                                            <div className="mb-4">
                                                <label className="form-label">Deal Offer Type</label>
                                                <select className="form-select"
                                                    {...register("deal_offer_type")}
                                                >

                                                    {calculator?.map(data => <option value={data?.name} selected={data?.name == productData?.deal[0]?.type}
                                                    >{data?.name} </option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_amount" className="form-label">Deal Time</label>
                                            <select className="form-select"
                                                {...register("deal_time_type")}
                                            >
                                                {calendar?.map(data => <option value={data?.name} selected={data?.name == productData?.deal[0]?.offer
                                                }
                                                >{data?.name} </option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="Promo_Offer_Price" className="form-label">Offer amount</label>
                                            <input type="number" className="form-control" id="Promo_Offer_Price"
                                                {...register("deal_amount")}
                                                defaultValue={productData?.deal[0]?.amount}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 mb-3">
                            <div className="mb-4">
                                <label className="form-label">Description text Counter</label>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '10px'

                                }}>
                                    <input type="text"
                                        style={{ backgroundColor: 'white' }}
                                        className="form-control"
                                        onChange={(e) => setSameWord(e.target.value)}
                                    />
                                    {sameWord && specificWordCount}
                                </div>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor='description' className="form-label"> Description</label>
                            <JoditEditor
                                ref={editor}
                                value={productData?.description}
                                onChange={(newContent) => contentFieldChanaged(newContent)}

                            />
                        </div>
                        <button style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} className="btn btn-md rounded font-sm hover-up">Update</button>
                    </form>
                </div>
            </section >
            <ExtraCostAddModel
                extraOpen={extraOpen}
                setExtraOpen={setExtraOpen}
                productData={productData}
            />
        </>
    )
}

export default ProductEdit