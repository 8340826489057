import { configureStore } from "@reduxjs/toolkit";
import { DepartmentApi } from "./services/department";
import authSlice from "./features/authSlice";
import { CategoriesApi } from "./services/categories";
import { SubCategoriesApi } from "./services/subCategories";
import { ChildSubCategoriesApi } from "./services/childsubCategories";
import { ProductApi } from "./services/product";
import { DashboardApi } from "./services/dashboard";
import { SeoApi } from "./services/seo";
import { BlogsApi } from "./services/blog";
import { BrandApi } from "./services/brand";
import { UserdApi } from "./services/user";
import { OnlineOrderApi } from "./services/onlineOrder";
import { SalesApi } from "./services/sales";
export const store = configureStore({
  reducer: {
    [DepartmentApi.reducerPath]: DepartmentApi.reducer,
    [CategoriesApi.reducerPath]: CategoriesApi.reducer,
    [SubCategoriesApi.reducerPath]: SubCategoriesApi.reducer,
    [ChildSubCategoriesApi.reducerPath]: ChildSubCategoriesApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [SeoApi.reducerPath]: SeoApi.reducer,
    [BlogsApi.reducerPath]: BlogsApi.reducer,
    [BrandApi.reducerPath]: BrandApi.reducer,
    [UserdApi.reducerPath]: UserdApi.reducer,
    [OnlineOrderApi.reducerPath]: OnlineOrderApi.reducer,
    [SalesApi.reducerPath]: SalesApi.reducer,
    auth: authSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      DepartmentApi.middleware,
      CategoriesApi.middleware,
      SubCategoriesApi.middleware,
      ChildSubCategoriesApi.middleware,
      ProductApi.middleware,
      DashboardApi.middleware,
      SeoApi.middleware,
      BlogsApi.middleware,
      BrandApi.middleware,
      UserdApi.middleware,
      OnlineOrderApi.middleware,
      SalesApi.middleware,
    ]),
});