import React, { useState } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import GetSpinner from '../../helpers/shared/GetSpinner'
import SingleUser from '../../components/elements/user/SingleUser'
import { useGetUserQuery } from '../../app/services/user'
export default function UserAccountHandel({ role, title }) {
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
    const pathname = `page=${pageIndex}&limit=${pageSize}&search=${search}&role=${role}`;
    const { data, isLoading } = useGetUserQuery(pathname)


    return (
        <>
            <DashboardLayout>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">{title}  List</h2>
                        </div>
                        {/*  <div>
                            <a onClick={() => setEmployeeCreateModal({ type: !employeeCreateModal.type })} className="btn btn-primary btn-sm rounded">Create Employee</a>
                        </div> */}
                    </div>
                    <div className="card mb-4">
                        <header className="card-header">
                            <div className="row align-items-center">
                                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                                    {/*  <input onChange={(e) => setSearchValue(e.target.value)} type="text" placeholder="Search..." className="form-control"

                                    /> */}
                                </div>
                                <div className="col-md-2 col-6">
                                    <input type="date" defaultValue="02.05.2021" className="form-control" />
                                </div>
                                <div className="col-md-2 col-6">
                                    <select className="form-select">
                                        <option selected>Status</option>
                                    </select>
                                </div>
                            </div>
                        </header>
                        <div className="col-md-12 px-4 ">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr className=''>
                                            <th>Name</th>
                                            {
                                                role == 'consumer' && <th>Email</th>
                                            }
                                            {
                                                role !== 'consumer' && <th>Company Name</th>
                                            }
                                            {
                                                role !== 'consumer' && <th>Company Email</th>
                                            }
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Status</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {Loading && <GetSpinner />} */}
                                        {data?.user_result?.map(data => <SingleUser key={data.key} data={data} role={role} />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    {/* <Pagination totalPages={data?.totalPages} setPagination={setPagination} pageIndex={pageIndex} pageSize={pageSize} /> */}
                </section>
                {/* <EmployeeCreateModal
                    modal={employeeCreateModal}
                    setOpen={setEmployeeCreateModal}
                />
                <EmployeeEditModal
                    modal={employeeEditModal}
                    setOpen={setEmployeeEditModal}
                /> */}
            </DashboardLayout>
        </>
    )
}
