import React, { } from 'react'
import item from '../../../assets/imgs/items/1.jpg'
function SingleProdutType({ data, ProductTypeDelete,isLoading}) {
    const showImages = data?.images[0]?.image?.[0];
    return (
        <article className="itemlist">
            <div className="row align-items-center">
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                    <a className="itemside" href="#">
                        <div className="left">
                            <img src={showImages ? showImages : item} className="img-sm img-thumbnail" alt="Item" />
                        </div>
                        <div className="info">
                            <h6 className="mb-0">{data?.product_name}</h6>
                        </div>
                    </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price"><span>${data?.saleing_Price}</span></div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                    <span className="badge rounded-pill alert-success">{data?.quantity}</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <a style={{ cursor: isLoading ? 'no-drop' : 'pointer' }} onClick={() => ProductTypeDelete(data?._id)} className="btn btn-sm font-sm btn-light rounded"> <i className="material-icons md-delete_forever"></i> Delete </a>
                </div>
            </div>
        </article>
    )
}

export default SingleProdutType