import React, { useEffect, useMemo, useState } from 'react'
import DashboardLayout from '../../components/layout/DashboardLayout'
import Pagination from '../../components/elements/Pagination'
import GetSpinner from '../../helpers/shared/GetSpinner'
import { useGetOrderSalesQuery } from '../../app/services/sales'
import SingleSaleView from '../../components/elements/sales/SingleSaleView'

function SalesRetailer({ role }) {
    const [Loading, setLoading] = useState(false)
    const [search, setSearchValue] = useState('')
    const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10, });
    const pathname = `role=${role}&page=${pageIndex}&limit=${pageSize}&search=${search}`;
    const { data, isLoading } = useGetOrderSalesQuery(pathname)

    const OrderData = useMemo(() => (data ? data?.onlineOrder : []), [
        data,
        search
    ]);

    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [isLoading])




    return (
        <DashboardLayout>
            <section className="content-main">
                <div className="content-header">
                    <div>
                        <h2 className="content-title card-title">Sales {role}</h2>
                        <p>{role} Sales List</p>
                    </div>
                    <div>
                        <input type="text" placeholder="Search order ID" className="form-control bg-white" />
                    </div>
                </div>
                <div className="card mb-4">
                    <header className="card-header">
                        <div className="row gx-3">
                            <div className="col-lg-4 col-md-6 me-auto">
                                <input type="text" placeholder="Search..." className="form-control" />
                            </div>
                            <div className="col-lg-2 col-6 col-md-3">
                                <select className="form-select">
                                    <option>Status</option>
                                    <option>Active</option>
                                    <option>Disabled</option>
                                    <option>Show all</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-6 col-md-3">
                                <select className="form-select">
                                    <option>Show 20</option>
                                    <option>Show 30</option>
                                    <option>Show 40</option>
                                </select>
                            </div>
                        </div>
                    </header>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Total Price</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Date</th>
                                        <th scope="col" className="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Loading ? <GetSpinner /> : <>
                                            {
                                                OrderData?.map(singleOder => <SingleSaleView
                                                    key={singleOder?._id}
                                                    singleOnlineOrder={singleOder} />)
                                            }


                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Pagination />
            </section>
        </DashboardLayout>
    )
}

export default SalesRetailer